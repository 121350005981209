import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import * as yup from "yup";
import { TransitionProps } from "@mui/material/transitions";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, Typography, TextField } from "@mui/material";
import AvatarChips from "../avatar-chips";
import { SubmitHandler, set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GroupForm } from "../../../models";
import { IWorkerModel } from "../../../../../../types";
import { useCreatePermissionGroup } from "../../../../../../api/permissions";

const GroupCreateSchema = yup.object().shape({
  name: yup
    .string()
    .required("Qrupun adı mütləq daxil edilməlidir")
    .max(50, "Qrupun adı maksimum 50 simvoldan ibarət olmalıdır"),
  description: yup
    .string()
    .required("Açıqlama mütləq daxil edilməlidir")
    .max(80, "Açıqlama maksimum 80 simvoldan ibarət olmalıdır"),
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
interface GroupBoxProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  users: IWorkerModel[];
  refetch: () => void;
}

function AddGroupModal(props: GroupBoxProps) {
  const [selectedUsers, setSelectedUsers] = useState<IWorkerModel[]>([]);
  const { open, setOpen, users, refetch } = props;
  const handleClose = () => {
    setOpen(false);
    reset();
    setSelectedUsers([]);
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<GroupForm>({
    resolver: yupResolver(GroupCreateSchema),
  });
  const mutation = useCreatePermissionGroup();
  const postGroupHandle: SubmitHandler<GroupForm> = async (data) => {
    const newData = {
      name: data.name,
      description: data.description,
      workersId: selectedUsers.map((user) => user.id),
    };
    mutation.mutateAsync(newData).then((res) => {
      refetch();
      handleClose();
      reset();
      setSelectedUsers([]);
    }).catch((err) => console.log(err));;
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-paper": {
          display: "flex",
          width: "600px",
          padding: "32px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "32px",
          backgroundColor: "#FFF",
          borderRadius: "16px",
          boxShadow: " 0px 23px 39px 0px rgba(0, 0, 0, 0.08)",
        },
        "& .MuiDialogTitle-root": {
          display: "flex",
          width: "536px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          padding: "0",
        },
        "& .MuiDialogTitle-root .header": {
          color: "#000",
          fontFamily: "Visby CF Bold",
          fontSize: "34px",
          lineHeight: "40px",
          letterSpacing: "0.4px",
        },
        "& .MuiDialogTitle-root .description": {
          color: "#131112",
          fontFamily: "Visby CF Regular, sans-serif",
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "0.4px",
        },
        "& .MuiDialogContent-root": {
          padding: 0,
          width: "100%",
        },

        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          border: "1px solid var(--Natural-natural-200, #999898)",
          borderRadius: "12px",
        },
        "& .MuiInputBase-input": {
          padding: "14px 24px",
        },
        "& .MuiInputBase-input::placeholder": {
          color: "var(--Natural-natural-300, #6B696A)",
          fontFamily: "Visby CF Medium, sans-serif",
          fontSize: "14px",
          lineHeight: "24px",
          letterSpacing: "0.4px",
        },
      }}
    >
      <DialogTitle>
        <Typography className="header" variant="h1">
          İstifadəçi qrupu əlavə et
        </Typography>
        <Typography className="description">
          Qrup əlavə etmək üçün qrupun adını və açıqlamasını daxil edin
        </Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form
          onSubmit={handleSubmit(postGroupHandle)}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "16px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Visby CF Bold, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.4px",
              }}
            >
              Qrupun adı
            </Typography>
            <TextField
              {...register("name")}
              fullWidth
              placeholder="Qrupun adını daxil edin"
              id="fullWidth"
              error={errors.name?.message !== undefined}
              helperText={errors.name?.message}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "16px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Visby CF Bold, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.4px",
              }}
            >
              İstifadəçilər
            </Typography>
            <Box
              display="flex"
              alignItems="flex-start"
              alignContent="flex-start"
              gap="12px"
              flexWrap="wrap"
            >
              {selectedUsers.map((user) => (
                <AvatarChips
                  key={user.id}
                  label={`${user.name} ${user.surname}`}
                  onDelete={() => {
                    setSelectedUsers(
                      selectedUsers.filter(
                        (selectedUser) => selectedUser.id !== user.id
                      )
                    );
                  }}
                />
              ))}
            </Box>
            <Autocomplete
              ListboxProps={{
                style: {
                  maxHeight: "150px",
                },
              }}
              fullWidth
              id="combo-box-demo"
              getOptionLabel={(option) => `${option.name} ${option.surname}`}
              options={users}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="İstifadəçi axtar"
                  id="fullWidth"
                />
              )}
              onChange={(event, value) => {
                if (!value) return;
                setSelectedUsers([...selectedUsers, value as IWorkerModel]);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "16px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Visby CF Bold, sans-serif",
                fontSize: "12px",
                lineHeight: "16px",
                letterSpacing: "0.4px",
              }}
            >
              Açıqlama{" "}
              <span
                style={{
                  color: "#999898",
                  fontFamily: "Visby CF Bold, sans-serif",
                }}
              >
                (*maksimum 80 simvol)
              </span>
            </Typography>
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "0",
                  borderRadius: "17px",
                  backgroundColor: "#F3F3F3",
                },
                "&:not(.Mui-focused) .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
              }}
              placeholder="Açıqlama əlavə edin..."
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              {...register("description")}
              error={errors.description?.message !== undefined}
              helperText={errors.description?.message}
            />
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: "16px" }}>
            <Button
              className="saveBtn"
              sx={{
                background: "#854693",
                color: "#FFF",
                fontSize: "16px",
                lineHeight: "24px",
                padding: "12px 24px",
                borderRadius: "12px",
                fontFamily: "Visby CF Bold, sans-serif",
                textTransform: "none",
                "&:hover": {
                  background: "#6e3d7d",
                },
              }}
              type="submit"
            >
              Yadda saxla
            </Button>
            <Button
              sx={{
                background: "#F3EDF4",
                color: "#0E0D0D",
                fontSize: "16px",
                lineHeight: "24px",
                padding: "12px 24px",
                borderRadius: "12px",
                fontFamily: "Visby CF Bold, sans-serif",
                textTransform: "none",
                "&:hover": {
                  background: "#D9C6DE",
                },
              }}
              onClick={handleClose}
            >
              Ləğv et
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AddGroupModal;
