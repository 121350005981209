import React, { useState } from "react";
import { GridColDef, GridRowId } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import DataGrid from "../../../../../components/custom-components/data-grid";
import { sxAvatar } from "../components/group-box/style";
import { sxFullname } from "./style";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";
import ConfirmModal from "../../../../../components/custom-components/confirm-modal";
import AddAnotherGroups from "./addAnotherGroups";
import { IPermissionGroupsModel, IWorkerModel } from "../../../../../types";
import { useDeleteUser } from "../../../../../api/users";
import { set } from "react-hook-form";
interface UserListProps {
  users: IWorkerModel[];
  refetchUsers: () => void;
  groups?: IPermissionGroupsModel[];
}
function UserList({ users, groups, refetchUsers }: UserListProps) {
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState<number>(8);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openAddAnotherGroupsModal, setOpenAddAnotherGroupsModal] =
    useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<GridRowId>();
  const mutation = useDeleteUser();
  const handleRemoveUser = async () => {
    mutation
      .mutateAsync(+selectedId!)
      .then(() => {
        refetchUsers();
        setOpenModal(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "İstifadəçi adı",
      flex: 2,
      renderCell: (params) => {
        return (
          <Box display="flex" alignItems="center" gap="10px">
            <Avatar sx={{ ...sxAvatar, bgcolor: params.row.avatarColor }}>
              {params.row.name[0]}
              {params.row.surname[0]}
            </Avatar>
            <Typography sx={sxFullname}>
              {params.row.name} {params.row.surname}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 2,
      renderCell: (params) => (
        <Typography sx={sxFullname}>{params.row.email}</Typography>
      ),
    },
    {
      field: "groups",
      headerName: "Qrup",
      flex: 1,
      renderCell: (params) => (
        <Typography sx={sxFullname}>{params.row.groups}</Typography>
      ),
    },
    {
      field: "operations",
      headerName: "Əməliyyat",
      flex: 1,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "12px",
            }}
          >
            <Box
              onClick={() => (
                setOpenAddAnotherGroupsModal(true), setSelectedId(params.id)
              )}
            >
              <Tooltip title="Başqa qrupa əlavə et">
                <img src="/icons/long-arrow-right.svg" alt="edit Icon" />
              </Tooltip>
            </Box>
            <Box
              onClick={(event) => {
                navigate(
                  `${APP_PREFIX_PATH}/permissions/user-access/${params.id}`
                );
              }}
            >
              <Tooltip title="Redaktə et">
                <img src="/icons/edit-light.svg" alt="edit Icon" />
              </Tooltip>
            </Box>
            <Box onClick={() => (setOpenModal(true), setSelectedId(params.id))}>
              <Tooltip title="Sil">
                <img src="/icons/remove-light.svg" alt="remove Icon" />
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];
  return (
    <>
      <DataGrid
        data={users}
        columnsData={columns}
        pageSizeData={pageSize}
        setPageSize={setPageSize}
      />
      <ConfirmModal
        handleAgree={() => handleRemoveUser()}
        text="İstifadəçini silmək istədiyinizdən əminsinizmi?"
        open={openModal}
        handleClose={() => setOpenModal(false)}
        isWarning={true}
        sendBtnText="İstifadəçini sil"
      />
      <AddAnotherGroups
        groups={groups!}
        open={openAddAnotherGroupsModal}
        setOpen={setOpenAddAnotherGroupsModal}
        userId={+selectedId!}
      />
    </>
  );
}

export default UserList;
