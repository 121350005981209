import { Typography } from "@mui/material"

export interface ICardModel{
    image: string,
    title: string,
    subtitle: string,
    color: string,
    onClick?: any,
}

export default function Card({image, title, subtitle, color, onClick}: ICardModel) {
  return (
    <div onClick={onClick} style={{padding: '16px', paddingTop: '12px', paddingBottom: '12px', borderRadius: '16px', border: '1px solid #D4D4D4', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}}>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{width: '42px', height: '42px', borderRadius: '50%', backgroundColor: color, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <img src={image} />
          </div>
          <div style={{marginLeft: '12px'}}>
              <Typography sx={{fontWeight: 700}}>
                  {title}
              </Typography>
              <Typography sx={{fontSize: '12px'}}>
                 {subtitle}
              </Typography>
          </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M7 9.5L12 14.5L17 9.5H7Z" fill="black" fill-opacity="0.54"/>
        </svg>
    </div>
  )
}
