import React, {useState} from 'react'
import PageHeader from '../../../../../../components/custom-components/page-header'
import Breadcrumbs from '../../../../../../components/custom-components/breadcrumbs'
import BreadcrumbsLink from '../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink'
import BreadcrumbsTypography from '../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography'
import PageTitle from '../../../../../../components/custom-components/page-title'
import { Box, Chip } from '@mui/material';
import { APP_PREFIX_PATH } from '../../../../../../configs/Appconfig'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import Button from '../../../../../../components/core-components/inputs/button'
import Drawer from '@mui/material/Drawer';
import GroupFilter from '../groupFilter';


interface PropsModel {
    programs: string[],
    program: string[],
    setProgram: React.Dispatch<React.SetStateAction<string[]>>
}

function Header(props: PropsModel) {

    const { programs, program, setProgram } = props
    const [filter, setFilter] = useState(false);

    return (
        <>
            <PageHeader>
                <Box>
                    <Breadcrumbs>
                        <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
                        <BreadcrumbsLink name="Akademiya" url={APP_PREFIX_PATH} />
                        <BreadcrumbsTypography name="Qruplar" />
                    </Breadcrumbs>
                    <PageTitle name="Qruplar" />
                </Box>
                <Box>
                    <Button onClick={()=> setFilter(true)}>
                        <FilterAltOutlinedIcon sx={{mr: 1}}/>
                        Filter
                    </Button>
                    <Drawer
                      anchor='right'
                      open={filter}
                      onClose={() => setFilter(!filter)}
                    >
                      <GroupFilter setFilter={setFilter}/>
                    </Drawer>
                </Box>
            </PageHeader>
            <Box>
                {program.map((option: string, index: number) => (
                    <Chip
                        key={option}
                        label={option}
                        color='primary'
                        onDelete={() => {
                            const newValues = [...program];
                            newValues.splice(index, 1);
                            setProgram(newValues);
                        }}
                        sx={{
                            marginTop: "8px",
                            marginRight: "8px",
                        }}
                    />
                ))}
            </Box>


        </>
    )
}

export default Header