import { useEffect, useState } from "react";
import ClassSelection from "./ClassSelection";
import LessonDuration from "./LessonDuration";
import { useGetWorkerLoginClass } from "../../../../../../api/workers";

export default function Index({setClass} : any) {
    const {data: workerLogin, isLoading} = useGetWorkerLoginClass();
    const [currentClass, setCurrentClass] = useState<any>(null);

    useEffect(()=> {
        if(currentClass){
          setClass(currentClass)
        }
    }, [currentClass])

  return (
    <>
      {
        workerLogin ? (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
              <ClassSelection workerLogin={workerLogin} currentClass={currentClass} setCurrentClass={setCurrentClass} />
              <LessonDuration/>
          </div>
        ) : null
      }
    
    </>
  )
}
