import React from "react";
import { Box } from "@mui/material";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";
import Breadcrumbs from "../../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsTypography from "../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import BreadcrumbsLink from "../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import PageTitle from "../../../../../../components/custom-components/page-title";
import Button from "../../../../../../components/core-components/inputs/button";
import PageHeader from "../../../../../../components/custom-components/page-header";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { sxFilter } from "./style";

interface PropsModel {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userRole: string;
  isAdmin?:boolean;
}
function Header(props: PropsModel) {
  const { setOpen, userRole,isAdmin } = props;
  return (
    <>
      <PageHeader>
        <Box>
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Akademik şöbə" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Davamiyyət göstəriciləri" />
          </Breadcrumbs>
          <Box>
            <PageTitle name="Davamiyyət göstəriciləri" />
          </Box>
        </Box>
        {!isAdmin? (
          <></>
        ) : (
          <Button style={sxFilter} onClick={() => setOpen(true)}>
            <FilterAltOutlinedIcon
              sx={{
                width: "20px",
                height: "20px",
              }}
              color="primary"
            />
            Filter
          </Button>
        )}
      </PageHeader>
    </>
  );
}

export default Header;
