import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Breadcrumbs from "../../../../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import { APP_PREFIX_PATH } from "../../../../../../../../configs/Appconfig";
import Select from "../../../../../../../../components/core-components/inputs/select";
import PageTitle from "../../../../../../../../components/custom-components/page-title";
import PageHeader from "../../../../../../../../components/custom-components/page-header";
import Button from "../../../../../../../../components/core-components/inputs/button";
import { Module } from "../../models/Module";
import { SubModule } from "../../models/SubModule";
import { PostData } from "../../models/PostData";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ApiInstance } from "../../../../../../../../network/services/core/apiInstance";
import { AlertColor } from "@mui/material/Alert";
import { ROLES } from "../../../../../../../../constants/auth/roles";

interface propsModel {
  instructor: string;
  mentor: string;
  instructors: string[];
  mentors: string[];
  setInstructor: React.Dispatch<React.SetStateAction<string>>;
  setMentor: React.Dispatch<React.SetStateAction<string>>;
  data: Module[];
  copiedData: Module[];
  checked: string[];
  postData: PostData[];
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setData: React.Dispatch<React.SetStateAction<Module[]>>;
  setAlertInside: any;
}

function Header(props: propsModel) {
  const navigate = useNavigate();
  const {
    instructor,
    setInstructor,
    mentor,
    setMentor,
    instructors,
    mentors,
    data,
    checked,
    copiedData,
    postData,
    setLoading,
    setAlertInside,
    setAlertOpen,
    setData,
  } = props;

  const params = useParams();

  useEffect(() => {
    if (checked.length > 0) {
      let checkedMentors: string[] = [];
      let checkedInstructors: string[] = [];
      copiedData.filter((item) => {
        if (checked.includes(item.id)) {
          if (
            item.workers.length === 1 &&
            item.workers[0].role === ROLES.MUELLIM
          ) {
            checkedMentors.push(" ");
            checkedInstructors.push(
              `${item.workers[0].name} ${item.workers[0].surname}`
            );
          } else if (
            item.workers.length === 1 &&
            item.workers[0].role === ROLES.MENTOR
          ) {
            checkedInstructors.push(" ");
            checkedMentors.push(
              `${item.workers[0].name} ${item.workers[0].surname}`
            );
          } else if (item.workers.length === 0) {
            checkedMentors.push(" ");
            checkedInstructors.push(" ");
          } else {
            item.workers.map((worker) => {
              if (worker.role === ROLES.MENTOR) {
                checkedMentors.push(`${worker.name} ${worker.surname}`);
              } else if (worker.role === ROLES.MUELLIM) {
                checkedInstructors.push(`${worker.name} ${worker.surname}`);
              }
            });
          }
        }

        item.subModules.filter((subModule: SubModule) => {
          if (checked.includes(subModule.id)) {
            if (
              subModule.workers.length === 1 &&
              subModule.workers[0].role === ROLES.MUELLIM
            ) {
              checkedMentors.push(" ");
            } else if (
              subModule.workers.length === 1 &&
              subModule.workers[0].role === ROLES.MENTOR
            ) {
              checkedInstructors.push(" ");
            } else if (subModule.workers.length === 0) {
              checkedMentors.push(" ");
              checkedInstructors.push(" ");
            } else {
              subModule.workers.map((worker) => {
                if (worker.role === ROLES.MENTOR) {
                  checkedMentors.push(`${worker.name} ${worker.surname}`);
                } else if (worker.role === ROLES.MUELLIM) {
                  checkedInstructors.push(`${worker.name} ${worker.surname}`);
                }
              });
            }
          }
        });
      });

      if (
        checkedMentors.every(
          (val: string, i: number, arr: string[]) => val === arr[0]
        )
      ) {
        if (checkedMentors[0]) {
          setMentor(checkedMentors[0]);
        }
      } else {
        setMentor("Mixed");
      }
      if (
        checkedInstructors.every(
          (val: string, i: number, arr: string[]) => val === arr[0]
        )
      ) {
        if (checkedInstructors[0]) {
          setInstructor(checkedInstructors[0]);
        }
      } else {
        setInstructor("Mixed");
      }
    }
  }, [checked, copiedData]);

  const handleClick = () => {
    setLoading(true);
    setData(copiedData);
    ApiInstance.post(`api/classes/${params.id}`, postData)
      .then((res) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Yadda saxlanıldı",
          severity: "success" as AlertColor,
        });
      })
      .then(() => {
        // setTimeout(() => {
        //  navigate(`${APP_PREFIX_PATH}/groups`);
        // }, 1500);
      })

      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <PageHeader>
        <Box>
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Akademik şöbə" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink
              name="Proqram təyin et"
              url={`${APP_PREFIX_PATH}/program/set`}
            />
            <BreadcrumbsTypography name={`${data[0]?.className}`} />
          </Breadcrumbs>
          <PageTitle name={`${data[0]?.className}`} />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Select
            name="Müəllim"
            selectElement={instructor}
            setSelectElement={setInstructor}
            selectElements={instructors.map((item:string , index) => ({
              id: index.toString(),
              label: item,
            }))}
            hiddenValue={"Mixed"}
            disabled={checked.length ? false : true}
          />
          <Select
            name="Mentor/ M.K"
            selectElement={mentor}
            setSelectElement={setMentor}
            selectElements={mentors.map((item:string , index) => ({
              id: index.toString(),
              label: item,
            }))}
            hiddenValue={"Mixed"}
            disabled={checked.length ? false : true}
          />
          <Button onClick={() => handleClick()}>Yadda saxla</Button>
        </Box>
      </PageHeader>
    </>
  );
}

export default Header;
