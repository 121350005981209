import { Box } from '@mui/system'
import React from 'react'
import HeaderNav from '../../components/layout-components/header'
import LeftMenu from '../../components/layout-components/left-menu'
import { Outlet } from "react-router-dom";

function AppLayout() {
  return (<>
    <Box sx={{ display: "flex"}}>
      <LeftMenu />
      <Box sx={{width:"100%"}}>
        <HeaderNav />
        <Box 
        sx={{
            padding:"20px 76px 40px 76px",
          }}
        >
          <Outlet /> 
        </Box>
      </Box>
    </Box>
  </>
  )
}

export default AppLayout