import React from 'react'
import { Box } from '@mui/material';
import Breadcrumbs from '../../../../../../../../components/custom-components/breadcrumbs';
import BreadcrumbsLink from '../../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink';
import BreadcrumbsTypography from '../../../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography';
import { APP_PREFIX_PATH } from '../../../../../../../../configs/Appconfig';
import PageTitle from '../../../../../../../../components/custom-components/page-title';


function Header() {
  return (
    <>
      <Box marginBottom={"24px"} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Akademik" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="Proqramlar" />
          </Breadcrumbs>
          <PageTitle name="Proqramlar" />
        </Box>
      </Box>

    </>
  )
}

export default Header