import { Table, TableContainer } from "@mui/material";
import { useState } from "react";
import Header from "./components/Header";
import Row from "./components/Row";

export default function Index({type, students} : any) {
  return (
    <TableContainer>
        <Table>
            <Header type={type}/>
            {
                students?.map((student : any, index : number)=> (
                    <Row index={index+1} id={student.id} fullName={student.name + " " + student.surname} note={student.note} attendance={student.attendance} totalHours={student.totalHours} isChecked={student.isChecked}/>
                ))
            }
        </Table>
    </TableContainer>
  )
}
