import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/system";
import { Typography } from "@mui/material";
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#6B696A",
  "&.Mui-checked": {
    color: "#468CBC",
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#888",
  },
}));
interface CheckboxLabelsProps {
  label?: string;
  disabled: boolean;
  checked: boolean;
  id: number;
  handleClick: any;
}
export default function CheckboxLabels(props: CheckboxLabelsProps) {
  const { id, label, disabled, checked, handleClick } = props;
  return (
    <FormGroup>
      <FormControlLabel
        control={<CustomCheckbox color="primary" />}
        disabled={disabled}
        label={
          <Typography
            style={{ fontFamily: "Visby CF Medium", whiteSpace: "nowrap" }}
          >
            {label && label}
          </Typography>
        }
        checked={checked}
        onChange={handleClick}
      />
    </FormGroup>
  );
}
