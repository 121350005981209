import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Select, MenuItem, FormControl } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "../../../../../../components/core-components/inputs/button";
import PageHeader from "../../../../../../components/custom-components/page-header";
import { CustomSelectModal } from "../CustomSelectModal";
import { BaseService } from "../../../../../../network/services/base/BaseService";
import { AlertColor } from "@mui/material/Alert";
import { Module } from "../../../../shared/continuity/models/Module";
import { LessonFormat } from "../../../../shared/continuity/models/LessonFormat";
import { Student } from "../../../../shared/continuity/models/Student";
import { Class } from "../../../../shared/continuity/models/Class";
import { CategoryHours } from "../../../../shared/continuity/models/CategoryHours";
import { Teachers } from "../../../../shared/continuity/models/Teachers";
import { Dialog } from "../../../../../../components/custom-components/dialog";

import Filters from "../filters";
import { HeldModule } from "../../../../shared/continuity/models/HeldModule";
import { useBanner } from "../../../../../../contexts/BannerContext";

interface DialogState{
  open:boolean,
  text:string
}

interface PropsModel {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setAlertInside: any;
  modules: Module[];
  index: number;
  lessonFormat: LessonFormat;
  students: Student[];
  teachers: Teachers[];
  selectedClass: Class;
  getStudents: () => void;
  getTeacherByClassId: () => void;
  today: string;
  userId: number;
  categoryHours: CategoryHours[];
  format: string;
  setFormat: React.Dispatch<React.SetStateAction<string>>;
  hasTheoric: boolean;
  hasPratice: boolean;
  hasLab: boolean;
  teacherChecks?: any;
  classes: Class[];
  setSelectedClass: React.Dispatch<React.SetStateAction<Class>>;
  setLessonFormat: React.Dispatch<React.SetStateAction<LessonFormat>>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  totalHours?: any;
  heldModules:HeldModule[]
  setHeldModules:React.Dispatch<React.SetStateAction<HeldModule[]>>,
  isGreatherThanCategoryHour: (roleName: string, totalHours: number) => number | boolean | undefined;
  reload?: boolean;
  setReload?: any;
}

function Header(props: PropsModel) {
  const {
    modules,
    index,
    lessonFormat,
    students,
    teachers,
    selectedClass,
    setLoading,
    setAlertInside,
    setAlertOpen,
    getStudents,
    setLessonFormat,
    setIndex,
    today,
    getTeacherByClassId,
    categoryHours,
    hasTheoric,
    hasPratice,
    hasLab,
    classes,
    setSelectedClass,
    heldModules,
    setHeldModules,
    isGreatherThanCategoryHour,
    reload,
    setReload
  } = props;
  const { toggleBannerVisibility } = useBanner();
  const [isClassesModalOpen,setIsClassesModalOpen]=useState<boolean>(false)
  const handleChange = (value: string) => {
    const selectedClassName = value;
    const selectedClass = classes.find(
      (item: Class) => item.name === selectedClassName
    );
    setSelectedClass && setSelectedClass(selectedClass!)
    setIsClassesModalOpen(false)
  };
  const modalContainerRef = useRef<HTMLDivElement | null>(null); 
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
        const modalContainer = modalContainerRef.current;
        if (isClassesModalOpen && modalContainer && !modalContainer.contains(event.target as Node)) {
          setIsClassesModalOpen(false)
        }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
        document.removeEventListener("click", handleOutsideClick);
    };
}, [isClassesModalOpen]);
  const [disabledBtn, setDisabledBtn] = useState<boolean>(true);
  
  const initialDialogState = {
    open: false,
    text: "",
  };
  const [dialogState, setDialogState] =
    useState<DialogState>(initialDialogState);
  const handleClose = () => {
    setDialogState((oldState: any) => ({ ...oldState, open: false }));
  };

  useEffect(() => {
    if(lessonFormat.name === "Dərs ləğv edildi" || lessonFormat.name === "Lab ləğv edildi" ){
      setDisabledBtn(false);
    }
    else if(hasLab && students.some((x) => x.sessions.some((y) => y.hour !== 0))){
      if (teachers.some((x) => x.attendanceStatus === "0")) {
        setDisabledBtn(true);
      } else if (
        teachers.some(
          (x) =>
            x.totalHours === null ||
            x.totalMinutes === null ||
            isGreatherThanCategoryHour(x.roleName, x.totalHours)
        )
      ) {
        setDisabledBtn(true);
      } else {
        setDisabledBtn(false);
      }
    }
    else if (
      students.some((x) => x.sessions.some((y) => y.hour !== 0)) &&
      Object.keys(heldModules[0]).length !== 0
    ) {
      if (teachers.some((x) => x.attendanceStatus === "0")) {
        setDisabledBtn(true);
      } else if (
        teachers.some(
          (x) =>
            x.totalHours === null ||
            x.totalMinutes === null ||
            isGreatherThanCategoryHour(x.roleName, x.totalHours)
        )
      ) {
        setDisabledBtn(true);
      } else {
        setDisabledBtn(false);
      }
    } else {
      setDisabledBtn(true);
    }
  }, [students, heldModules, teachers, lessonFormat]);

  const classesSessionsService = new BaseService("api/attendances");
  const cancelClassService = new BaseService(`api/classes`);
  const handleAtendancesWorkers: any = (category: string) => {
    const atendancesWorkersArr: any = [];
    teachers.map((teacher, index) => {
      if (hasTheoric && hasLab) {
        if (category === "Theoric") {
          if (teacher.roleName === "muellim") {
            return atendancesWorkersArr.push({
              workerId: teacher.workerId,
              roleId: teacher.roleId,
              totalHours: teacher.totalHours,
              totalMinutes: teacher.totalMinutes,
              attendanceStatus: teacher.attendanceStatus,
            });
          }
        }
        if (category === "Lab") {
          if (teacher.roleName === "mentor") {
            return atendancesWorkersArr.push({
              workerId: teacher.workerId,
              roleId: teacher.roleId,
              totalHours: teacher.totalHours,
              totalMinutes: teacher.totalMinutes,
              attendanceStatus: teacher.attendanceStatus,
            });
          }
        }
      } else {
        return atendancesWorkersArr.push({
          workerId: teacher.workerId,
          roleId: teacher.roleId,
          totalHours: teacher.totalHours,
          totalMinutes: teacher.totalMinutes,
          attendanceStatus: teacher.attendanceStatus,
        });
      }
    });
    return atendancesWorkersArr;
  };
  const updateSessions = (category: string) => {
    return {
      category: category,
      status: (() => {
        if (lessonFormat.value === 3) {
          if (lessonFormat.name === "Dərs ləğv edildi") {
            if (category === "Theoric") {
              return lessonFormat.value;
            } else {
              return 1;
            }
          } else if (lessonFormat.name === "Praktika ləğv edildi") {
            if (category === "Practice") {
              return lessonFormat.value;
            } else {
              return 1;
            }
          } else if (lessonFormat.name === "Lab ləğv edildi") {
            if (category === "Lab") {
              return lessonFormat.value;
            } else {
              return 1;
            }
          } else {
            return lessonFormat.value;
          }
        } else {
          return lessonFormat.value;
        }
      })(),
      attendancesWorkers: handleAtendancesWorkers(category),
      attendances: students.map((student) => {
        const session = student.sessions.find(
          (session) => session.classSessionCategory === category
        );
        return {
          studentId: student.studentId,
          note: session?.note,
          totalAttendanceHours: session?.hour,
        };
      }),
    };
  };
  const handleUpdateSessions = (
    hasTheoric: boolean,
    hasPratice: boolean,
    hasLab: boolean
  ) => {
    if (hasTheoric && hasLab) {
      return [updateSessions("Theoric"),updateSessions("Lab")];
    }
    if (hasTheoric) {
      return [updateSessions("Theoric")];
    }
    if (hasLab) {
      return [updateSessions("Lab")]
    }
  };
  const updatedHeldModules = heldModules.map((module) => {
    const updatedModule = { ...module };
    delete updatedModule.moduleName;

    return updatedModule;
  });
  const handleClick = () => {

    setLoading(true);

    const data = {
      classId: selectedClass.id,
      date: today,
      // sessions: [updateSessions("Theoric"), updateSessions("Practice")],
      sessions: handleUpdateSessions(hasTheoric, hasPratice, hasLab) || [],
      heldModules: hasLab&& !hasTheoric?null:updatedHeldModules,
    };

    if(lessonFormat.name === "Dərs ləğv edildi" || lessonFormat.name === "Lab ləğv edildi" ){
      cancelClassService
      .cancel(data, `/api/classes/${selectedClass.id}/session-cancel?date=${data.date}`)
      .then((res) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanıldı",
          severity: "success" as AlertColor,
        });
        getStudents();
        toggleBannerVisibility()
        return getTeacherByClassId();
      })
      .then((teacherData) => {
      })
      .catch((err) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanılmadı",
          severity: "error" as AlertColor,
        });
      })
      .finally(() => {
        setLoading(false);
      });
    }else{
      classesSessionsService
      .add(data)
      .then((res) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanıldı",
          severity: "success" as AlertColor,
        });
        getStudents();
        toggleBannerVisibility()
        return getTeacherByClassId();
      })
      .then(() => {
        setReload(!reload);
      })
      .catch((err) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Dəyişikliklər yadda saxlanılmadı",
          severity: "error" as AlertColor,
        });
      })
      .finally(() => {
        setLoading(false);
        // setReload(!reload);
      });
    }
    
  };

  return (
    <>
      <PageHeader>
        <Box display="flex" alignItems="center" gap="56px">
          <Box ref={modalContainerRef} onClick={()=>setIsClassesModalOpen(!isClassesModalOpen)} display="flex" flexDirection="column" alignItems="flex-start" position='relative'sx={{cursor:"pointer"}}>
            <Box display="flex" alignItems="center" gap="8px">
              <Typography
                variant="h1"
                sx={{
                  fontSize: "24px",
                  fontFamily: "Visby CF Bold, sans-serif",
                }}
                lineHeight="32px"
              >
                {selectedClass?.name ?? ""}
              </Typography>
              <ArrowDropDownIcon
                sx={{ width: "24px", height: "24px", color: "#6B696A" }}
              />
              <CustomSelectModal isOpen={isClassesModalOpen} data={classes} handleChange={handleChange} />
            </Box>
            <Box
              display="flex"
              color="#6B696A"
              alignItems="center"
              fontSize="12px"
              fontFamily="Visby CF, sans-serif"
              letterSpacing="0.4px"
            >
              Keçirilib:
              <Typography
                sx={{
                  fontFamily: "Visby CF Bold, sans-serif",
                  color: "#0E0D0D",
                  fontSize: "12px",
                }}
              >
                {`${selectedClass?.attendanceHours}/${selectedClass?.totalHours} Saat`}
              </Typography>
            </Box>
          </Box>
          <Filters
            hasCalendar={false}
            heldModules={heldModules}
            setHeldModules={setHeldModules}
            totalHours={selectedClass?.totalHours}
            modules={modules}
            index={index}
            setIndex={setIndex}
            lessonFormat={lessonFormat}
            setLessonFormat={setLessonFormat}
            categoryHours={categoryHours}
            hasTheoric={hasTheoric}
            hasPratice={hasPratice}
            hasLab={hasLab}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
          <Button disabled={disabledBtn} onClick={handleClick}>
            Yadda saxla
          </Button>
        </Box>
      </PageHeader>
      {dialogState.open && (
        <Dialog
          text={dialogState.text}
          open={dialogState.open}
          handleClose={handleClose}
        />
      )}
    </>
  );
}

export default Header;
