import { apiRoutes } from "../apiRoutes";
import { IPermissionGroupsModel } from "../types";
import { useFetch,usePost,useDelete } from "../utils/api/reactQuery";

export const useGetPermissionGroupsList = () =>
    useFetch<IPermissionGroupsModel[]>(apiRoutes.permissions.getAll);
export const useGetPermissionsGroupsById = (id: number) =>
    useFetch<IPermissionGroupsModel>(apiRoutes.permissions.getById(id));
export const useCreatePermissionGroup = ( () => usePost<any, any>(apiRoutes.permissions.create, undefined));
export const useAddUserToPermissionGroup = ( () => usePost('', undefined));
export const useDeletePermissionGroup = ( () => useDelete(apiRoutes.permissions.delete));

   