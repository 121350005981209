import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { Box, Typography, TableRow } from "@mui/material";
import { AppModule, AppModulesGroup, SubAppModule } from "../../../../models";
import CheckboxLabels from "../../../../../../../components/custom-components/checkbox-labels";



interface IRowProp {
  appModuleId: number;
  onArrowClick?: any;
  topAppModule?: AppModule;
  isArrowClicked?: boolean;
  subAppModule?: SubAppModule;
  setAppModulesGroup: React.Dispatch<React.SetStateAction<AppModulesGroup>>;
}
function Row(props: IRowProp) {
  const {onArrowClick, topAppModule, subAppModule, isArrowClicked,setAppModulesGroup } = props;
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>,id:number) => {
    setAppModulesGroup((oldAppModules) => {
      let current:AppModulesGroup  = {
          ...oldAppModules,
          appModules: oldAppModules.appModules.map((appModule:AppModule) => {
            if(appModule.id === topAppModule?.id){
              return {
                ...appModule,
                permissionAccesses: appModule.permissionAccesses.map((permissionAccess) => {
                  if(appModule && permissionAccess.id === id){
                    return {
                      ...permissionAccess,
                      isAccess: event.target.checked
                    }
                  }
                  return permissionAccess;
                }),
                subAppModules: appModule.subAppModules.map((oldSubAppModule) => {
                  return {
                    ...oldSubAppModule,
                    permissionAccesses: oldSubAppModule.permissionAccesses.map((permissionAccess) => {
                      if(permissionAccess.id === id){
                        return {
                          ...permissionAccess,
                          isAccess: event.target.checked
                        }
                      }
                      return permissionAccess;
                    })
                  }
                })
              }
            }
            else{
              return {
                ...appModule,
                subAppModules: appModule.subAppModules.map((existedSubAppModule) =>{
                  if(existedSubAppModule.id !== subAppModule?.id) return existedSubAppModule;
                  return(
                    {
                      ...existedSubAppModule,
                      permissionAccesses: existedSubAppModule.permissionAccesses.map((permissionAccess) => {
                        if(permissionAccess.id === id){
                          return {
                            ...permissionAccess,
                            isAccess: event.target.checked
                          }
    
                        }
                        return permissionAccess;
                      })
                    }
                  )
                }),
              }
            }
          })
         }
     
         if (subAppModule) {
          const exactAppModule = current.appModules.find((appModule) =>
            appModule.subAppModules.some((subAppM) => subAppM.id === subAppModule?.id)
          );
        
          if (exactAppModule) {
            const isAllSubAppModuleAccessesChecked = exactAppModule.subAppModules.every((subAppM) =>
              subAppM.permissionAccesses.some((permissionAccess) => 
                permissionAccess.id === id && permissionAccess.isAccess === true
              )
            );
        
            const permission = exactAppModule.permissionAccesses.find((permissionAccess) => permissionAccess.id === id);
            
            if (permission) {
              permission.isAccess = isAllSubAppModuleAccessesChecked;
            }
          }
        }
     
      return current;
    });
    
  }
  return (
        <Box
          sx={{
            display: "flex",
            padding: topAppModule?"16px 0px":"4px 0px",
            justifyContent: "space-between",
            alignItems: "flex-end",
            alignSelf: "stretch",
            borderBottom: topAppModule?"1px solid #E9E9E9":"1px solid #E9E9E9",
            backgroundColor:subAppModule?"#F7F7F7":"#FFF",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: "8px",
              width: "260px",
            }}
          >
            <ArrowRightIcon
              onClick={onArrowClick}
              sx={{
                color: "#854693",
                cursor: "pointer",
                transition: "0.5s ease all",
                transform: `${isArrowClicked ? "rotate(90deg)" : "none"}`,
                visibility: topAppModule?"visible":"hidden",
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              gap="8px"
            >
              <Typography
                sx={{
                  fontSize: topAppModule?"16px":"14px",
                  fontFamily: "Visby CF Bold, sans-serif",
                }}
                component={"p"}
              >
                {topAppModule?.name || subAppModule?.name}
              </Typography>
             {
              topAppModule && (
                <Typography
                sx={{
                  fontSize: "12px",
                  fontFamily: "Visby CF Regular, sans-serif",
                  color: "#4E4C4D",
                  lineHeight: "16px",
                }}
                component={"p"}
              >
                Aliquet in egestas venenatis a proin scelerisque et nec
                sagittis.
              </Typography>
              )
             }
            </Box>
          </Box>
          {topAppModule &&
            topAppModule.permissionAccesses.map((permissionAccess) => (
              <CheckboxLabels
                id={permissionAccess.id}
                label={permissionAccess.name}
                disabled={false}
                checked={permissionAccess.isAccess}
                handleClick={(e:any) => handleCheckboxChange(e,permissionAccess.id)}
              />
            ))}
          {subAppModule &&
            subAppModule.permissionAccesses.map((permissionAccess) => (
              <CheckboxLabels
                id={permissionAccess.id}
                label={permissionAccess.name}
                disabled={false}
                checked={permissionAccess.isAccess}
                handleClick={(e:any) => handleCheckboxChange(e,permissionAccess.id)}
              />
            ))}
        </Box>
      )}

export default Row;
