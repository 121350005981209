import { Button as MaterialButtonComponent } from "@mui/material";


interface ButtonProps extends React.ComponentProps<typeof MaterialButtonComponent> {
  //Add additional prop definitions here
  //   mySpecificProp: string;
}

const Button = (props: ButtonProps) => {
  return (
    <MaterialButtonComponent
      variant="contained"
      type="submit"
      sx={{
        fontFamily: 'Visby CF Bold, sans-serif',
        padding: "8px 24px",
        borderRadius: "12px",
        fontSize: "14px",
        textTransform: "none",
        maxWidth: "220px",
        boxShadow:"none"
      }}
      {...props}>
      {props.children}
    </MaterialButtonComponent>
  );
};

export default Button;
