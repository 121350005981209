const permissions = {
    __edit: 'Edit',
    __create: 'Create',
    __delete: 'Delete',
    __export: 'Export',
    __view: 'View',
    __permissions: {
        mrp: 'mrp',
        educationProccesses: 'tadris_amaliyatlari',
        attendance: 'davamiyyat',
        attendanceRate: 'davamiyyat_gostericileri',
        students: 'talabalar',
        educationSystem: 'tadris_sistemi',
        workers: 'iscilar',
        groups: 'gruplar',
        programs: 'programlar',
        unmarkedAttendancies: 'yazilmamis_davamiyyatlar',
        humanResources: 'insan_resuslari',
        calendar: 'tagvim',
        academikStaff: 'akademik_heyat',
        roles: 'rol_tayin_et',
        support: 'dastak',
        supportsList: 'dastaklar',
        settings: 'tanzimlamalar',
        setPermissions: 'set_permissions',
        permissionLevel: 'permission_level',
        questionnarie: 'anket'
    },
};

export interface IPermissionModel{
    permissionName: string,
    permissionLevel: string
}

export const mrpView: IPermissionModel = {
    permissionName: permissions.__permissions.mrp,
    permissionLevel: permissions.__view
};

export const mrpCreate: IPermissionModel = {
    permissionName: permissions.__permissions.mrp,
    permissionLevel: permissions.__create
};

export const mrpEdit: IPermissionModel = {
    permissionName: permissions.__permissions.mrp,
    permissionLevel: permissions.__edit
};

export const mrpDelete: IPermissionModel = {
    permissionName: permissions.__permissions.mrp,
    permissionLevel: permissions.__delete
};

export const mrpExport: IPermissionModel = {
    permissionName: permissions.__permissions.mrp,
    permissionLevel: permissions.__export
};

export const educationProccessView: IPermissionModel = {
    permissionName: permissions.__permissions.educationProccesses,
    permissionLevel: permissions.__view
};

export const educationProccessCreate: IPermissionModel = {
    permissionName: permissions.__permissions.educationProccesses,
    permissionLevel: permissions.__create
};

export const educationProccessEdit: IPermissionModel = {
    permissionName: permissions.__permissions.educationProccesses,
    permissionLevel: permissions.__edit
};

export const educationProccessDelete: IPermissionModel = {
    permissionName: permissions.__permissions.educationProccesses,
    permissionLevel: permissions.__delete
};

export const educationProccessExport: IPermissionModel = {
    permissionName: permissions.__permissions.educationProccesses,
    permissionLevel: permissions.__export
};

export const attendanceView: IPermissionModel = {
    permissionName: permissions.__permissions.attendance,
    permissionLevel: permissions.__view
};

export const attendanceCreate: IPermissionModel = {
    permissionName: permissions.__permissions.attendance,
    permissionLevel: permissions.__create
};

export const attendanceEdit: IPermissionModel = {
    permissionName: permissions.__permissions.attendance,
    permissionLevel: permissions.__edit
};

export const attendanceDelete: IPermissionModel = {
    permissionName: permissions.__permissions.attendance,
    permissionLevel: permissions.__delete
};

export const attendanceExport: IPermissionModel = {
    permissionName: permissions.__permissions.attendance,
    permissionLevel: permissions.__export
};

export const attendanceRateView: IPermissionModel = {
    permissionName: permissions.__permissions.attendanceRate,
    permissionLevel: permissions.__view
};

export const attendanceRateCreate: IPermissionModel = {
    permissionName: permissions.__permissions.attendanceRate,
    permissionLevel: permissions.__create
};

export const attendanceRateEdit: IPermissionModel = {
    permissionName: permissions.__permissions.attendanceRate,
    permissionLevel: permissions.__edit
};

export const attendanceRateDelete: IPermissionModel = {
    permissionName: permissions.__permissions.attendanceRate,
    permissionLevel: permissions.__delete
};

export const attendanceRateExport: IPermissionModel = {
    permissionName: permissions.__permissions.attendanceRate,
    permissionLevel: permissions.__export
};

export const studentsView: IPermissionModel = {
    permissionName: permissions.__permissions.students,
    permissionLevel: permissions.__view
};

export const studentsCreate: IPermissionModel = {
    permissionName: permissions.__permissions.students,
    permissionLevel: permissions.__create
};

export const studentsEdit: IPermissionModel = {
    permissionName: permissions.__permissions.students,
    permissionLevel: permissions.__edit
};

export const studentsDelete: IPermissionModel = {
    permissionName: permissions.__permissions.students,
    permissionLevel: permissions.__delete
};

export const studentsExport: IPermissionModel = {
    permissionName: permissions.__permissions.students,
    permissionLevel: permissions.__export
};

export const educationSystemView: IPermissionModel = {
    permissionName: permissions.__permissions.educationSystem,
    permissionLevel: permissions.__view
};

export const educationSystemCreate: IPermissionModel = {
    permissionName: permissions.__permissions.educationSystem,
    permissionLevel: permissions.__create
};

export const educationSystemEdit: IPermissionModel = {
    permissionName: permissions.__permissions.educationSystem,
    permissionLevel: permissions.__edit
};

export const educationSystemDelete: IPermissionModel = {
    permissionName: permissions.__permissions.educationSystem,
    permissionLevel: permissions.__delete
};

export const educationSystemExport: IPermissionModel = {
    permissionName: permissions.__permissions.educationSystem,
    permissionLevel: permissions.__export
};

export const workersView: IPermissionModel = {
    permissionName: permissions.__permissions.workers,
    permissionLevel: permissions.__view
};

export const workersCreate: IPermissionModel = {
    permissionName: permissions.__permissions.workers,
    permissionLevel: permissions.__create
};

export const workersEdit: IPermissionModel = {
    permissionName: permissions.__permissions.workers,
    permissionLevel: permissions.__edit
};

export const workersDelete: IPermissionModel = {
    permissionName: permissions.__permissions.workers,
    permissionLevel: permissions.__delete
};

export const workersExport : IPermissionModel= {
    permissionName: permissions.__permissions.workers,
    permissionLevel: permissions.__export
};

export const groupsView: IPermissionModel = {
    permissionName: permissions.__permissions.groups,
    permissionLevel: permissions.__view
};

export const groupsCreate: IPermissionModel = {
    permissionName: permissions.__permissions.groups,
    permissionLevel: permissions.__create
};

export const groupsEdit: IPermissionModel = {
    permissionName: permissions.__permissions.groups,
    permissionLevel: permissions.__edit
};

export const groupsDelete: IPermissionModel = {
    permissionName: permissions.__permissions.groups,
    permissionLevel: permissions.__delete
};

export const groupsExport: IPermissionModel = {
    permissionName: permissions.__permissions.groups,
    permissionLevel: permissions.__export
};

export const programsView: IPermissionModel = {
    permissionName: permissions.__permissions.programs,
    permissionLevel: permissions.__view
};

export const programsCreate: IPermissionModel = {
    permissionName: permissions.__permissions.programs,
    permissionLevel: permissions.__create
};

export const programsEdit: IPermissionModel = {
    permissionName: permissions.__permissions.programs,
    permissionLevel: permissions.__edit
};

export const programsDelete: IPermissionModel = {
    permissionName: permissions.__permissions.programs,
    permissionLevel: permissions.__delete
};

export const programsExport: IPermissionModel = {
    permissionName: permissions.__permissions.programs,
    permissionLevel: permissions.__export
};

export const unmarkedAttendanciesView: IPermissionModel = {
    permissionName: permissions.__permissions.unmarkedAttendancies,
    permissionLevel: permissions.__view
};

export const unmarkedAttendanciesCreate: IPermissionModel = {
    permissionName: permissions.__permissions.unmarkedAttendancies,
    permissionLevel: permissions.__create
};

export const unmarkedAttendanciesEdit: IPermissionModel = {
    permissionName: permissions.__permissions.unmarkedAttendancies,
    permissionLevel: permissions.__edit
};

export const unmarkedAttendanciesDelete: IPermissionModel = {
    permissionName: permissions.__permissions.unmarkedAttendancies,
    permissionLevel: permissions.__delete
};

export const unmarkedAttendanciesExport: IPermissionModel = {
    permissionName: permissions.__permissions.unmarkedAttendancies,
    permissionLevel: permissions.__export
};

export const humanResourcesView: IPermissionModel = {
    permissionName: permissions.__permissions.humanResources,
    permissionLevel: permissions.__view
};

export const humanResourcesCreate: IPermissionModel = {
    permissionName: permissions.__permissions.humanResources,
    permissionLevel: permissions.__create
};

export const humanResourcesEdit: IPermissionModel = {
    permissionName: permissions.__permissions.humanResources,
    permissionLevel: permissions.__edit
};

export const humanResourcesDelete: IPermissionModel = {
    permissionName: permissions.__permissions.humanResources,
    permissionLevel: permissions.__delete
};

export const humanResourcesExport: IPermissionModel = {
    permissionName: permissions.__permissions.humanResources,
    permissionLevel: permissions.__export
};

export const calendarView: IPermissionModel = {
    permissionName: permissions.__permissions.calendar,
    permissionLevel: permissions.__view
};

export const calendarCreate: IPermissionModel = {
    permissionName: permissions.__permissions.calendar,
    permissionLevel: permissions.__create
};

export const calendarEdit: IPermissionModel = {
    permissionName: permissions.__permissions.calendar,
    permissionLevel: permissions.__edit
};

export const calendarDelete: IPermissionModel = {
    permissionName: permissions.__permissions.calendar,
    permissionLevel: permissions.__delete
};

export const calendarExport: IPermissionModel = {
    permissionName: permissions.__permissions.calendar,
    permissionLevel: permissions.__export
};

export const academikStaffView: IPermissionModel = {
    permissionName: permissions.__permissions.academikStaff,
    permissionLevel: permissions.__view
};


export const academikStaffCreate: IPermissionModel = {
    permissionName: permissions.__permissions.academikStaff,
    permissionLevel: permissions.__create
};

export const academikStaffEdit: IPermissionModel = {
    permissionName: permissions.__permissions.academikStaff,
    permissionLevel: permissions.__edit
};

export const academikStaffDelete: IPermissionModel = {
    permissionName: permissions.__permissions.academikStaff,
    permissionLevel: permissions.__delete
};

export const academikStaffExport: IPermissionModel = {
    permissionName: permissions.__permissions.academikStaff,
    permissionLevel: permissions.__export
};

export const rolesView: IPermissionModel = {
    permissionName: permissions.__permissions.roles,
    permissionLevel: permissions.__view
};

export const rolesCreate: IPermissionModel = {
    permissionName: permissions.__permissions.roles,
    permissionLevel: permissions.__create
};

export const rolesEdit: IPermissionModel = {
    permissionName: permissions.__permissions.roles,
    permissionLevel: permissions.__edit
};

export const rolesDelete: IPermissionModel = {
    permissionName: permissions.__permissions.roles,
    permissionLevel: permissions.__delete
};

export const rolesExport: IPermissionModel = {
    permissionName: permissions.__permissions.roles,
    permissionLevel: permissions.__export
};

export const supportView: IPermissionModel = {
    permissionName: permissions.__permissions.support,
    permissionLevel: permissions.__view
};

export const supportCreate: IPermissionModel = {
    permissionName: permissions.__permissions.support,
    permissionLevel: permissions.__create
};

export const supportEdit: IPermissionModel = {
    permissionName: permissions.__permissions.support,
    permissionLevel: permissions.__edit
};

export const supportDelete: IPermissionModel = {
    permissionName: permissions.__permissions.support,
    permissionLevel: permissions.__delete
};

export const supportExport: IPermissionModel = {
    permissionName: permissions.__permissions.support,
    permissionLevel: permissions.__export
};

export const supportsListView: IPermissionModel = {
    permissionName: permissions.__permissions.supportsList,
    permissionLevel: permissions.__view
};

export const supportsListCreate: IPermissionModel = {
    permissionName: permissions.__permissions.supportsList,
    permissionLevel: permissions.__create
};

export const supportsListEdit: IPermissionModel = {
    permissionName: permissions.__permissions.supportsList,
    permissionLevel: permissions.__edit
};

export const supportsListDelete: IPermissionModel = {
    permissionName: permissions.__permissions.supportsList,
    permissionLevel: permissions.__delete
};

export const supportsListExport: IPermissionModel = {
    permissionName: permissions.__permissions.supportsList,
    permissionLevel: permissions.__export
};

export const settingsView: IPermissionModel = {
    permissionName: permissions.__permissions.settings,
    permissionLevel: permissions.__view
};

export const settingsCreate: IPermissionModel = {
    permissionName: permissions.__permissions.settings,
    permissionLevel: permissions.__create
};

export const settingsEdit: IPermissionModel = {
    permissionName: permissions.__permissions.settings,
    permissionLevel: permissions.__edit
};

export const settingsDelete: IPermissionModel = {
    permissionName: permissions.__permissions.settings,
    permissionLevel: permissions.__delete
};

export const settingsExport: IPermissionModel = {
    permissionName: permissions.__permissions.settings,
    permissionLevel: permissions.__export
};

export const setPermissionsView: IPermissionModel = {
    permissionName: permissions.__permissions.setPermissions,
    permissionLevel: permissions.__view
};

export const setPermissionsCreate: IPermissionModel = {
    permissionName: permissions.__permissions.setPermissions,
    permissionLevel: permissions.__create
};

export const setPermissionsEdit: IPermissionModel = {
    permissionName: permissions.__permissions.setPermissions,
    permissionLevel: permissions.__edit
};

export const setPermissionsDelete: IPermissionModel = {
    permissionName: permissions.__permissions.setPermissions,
    permissionLevel: permissions.__delete
};

export const setPermissionsExport: IPermissionModel = {
    permissionName: permissions.__permissions.setPermissions,
    permissionLevel: permissions.__export
};

export const permissionLevelView: IPermissionModel = {
    permissionName: permissions.__permissions.permissionLevel,
    permissionLevel: permissions.__view
};

export const permissionLevelCreate: IPermissionModel = {
    permissionName: permissions.__permissions.permissionLevel,
    permissionLevel: permissions.__create
};

export const permissionLevelEdit: IPermissionModel = {
    permissionName: permissions.__permissions.permissionLevel,
    permissionLevel: permissions.__edit
};

export const permissionLevelDelete: IPermissionModel = {
    permissionName: permissions.__permissions.permissionLevel,
    permissionLevel: permissions.__delete
};

export const permissionLevelExport: IPermissionModel = {
    permissionName: permissions.__permissions.permissionLevel,
    permissionLevel: permissions.__export
};
export const anketView: IPermissionModel = {
    permissionName: permissions.__permissions.questionnarie,
    permissionLevel: permissions.__view
};
export const anketCreate: IPermissionModel = {
    permissionName: permissions.__permissions.questionnarie,
    permissionLevel: permissions.__create
};

export const anketEdit: IPermissionModel = {
    permissionName: permissions.__permissions.questionnarie,
    permissionLevel: permissions.__edit
};

export const anketDelete: IPermissionModel = {
    permissionName: permissions.__permissions.questionnarie,
    permissionLevel: permissions.__delete
};

export const anketExport: IPermissionModel = {
    permissionName: permissions.__permissions.questionnarie,
    permissionLevel: permissions.__export
};