import { createContext, useEffect, useState } from 'react';

const AttendanceStudentsContext = createContext<any>([]);

const AttendanceStudentsContextProvider = ({children} : any) => {
    const [students, setStudents] = useState<any>([]);
    const [areAllChecked, setAreAllChecked] = useState<boolean>(false);

    const handleStudentChecked = (studentId: any) => {
        setStudents(students.map((student: any)=> {
            if(student.id === studentId){
                return {...student, isChecked: !student.isChecked}
            }else{
                return student
            }
        }))
    }

    const handleStudentNoteAdd = (studentId : any, note: string ) => {
        setStudents(students.map((student: any)=> {
            if(student.id === studentId){
                return {...student, note: note}
            }else{
                return student
            }
        }))
    }

    const handleStudentTotalHoursChange = (studentId: any, totalHours: any) => {
        setStudents(students.map((student: any)=> {
            if(student.id === studentId){
                return {...student, totalHours: totalHours}
            }
            else{
                return student
            }
        }))
    }

    const handleAllStudentsCheck = (check : any) => {
        setStudents(students.map((student: any)=> {return {...student, isChecked: check}}));
        setAreAllChecked(true);
    }

    const handleSelectedStudentsAttendanceHours = (totalHours: any) => {
        setStudents(students.map((student: any) => student.isChecked ? {...student, totalHours: totalHours} : student))
    }

    useEffect(()=> {
        if(students?.some((x: any)=> !x.isChecked)){
            setAreAllChecked(false);
        }else{
            setAreAllChecked(true)
        }
    }, [students])

    const values = {
        students, 
        setStudents,
        handleStudentChecked,
        handleStudentNoteAdd,
        handleStudentTotalHoursChange,
        handleAllStudentsCheck,
        areAllChecked,
        handleSelectedStudentsAttendanceHours
    }

    return <AttendanceStudentsContext.Provider value={values as any}>
        {children}
    </AttendanceStudentsContext.Provider>
}

export {AttendanceStudentsContext, AttendanceStudentsContextProvider};