import {useEffect, useReducer, useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { Grid, TextField, IconButton } from "@mui/material";
import Button from "../../../../../components/core-components/inputs/button";
import { sxField } from "../../../MrpManagement/mrp/components/modal/style";
import { useForm, SubmitHandler, Controller} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {ISupport} from '../../types';
import {supportUpdateSchema} from '../../validations';
import Autocomplete from '@mui/material/Autocomplete';
import Alert from "../../../../../components/custom-components/alert";
import { AlertColor } from "@mui/material/Alert";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import styles from '../../style.module.css';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import ImageDetail from './ImageDetail';
import {useUpdateSupport, useGetSupportById} from '../../../../../api/supports';
import { useQueryClient } from 'react-query';
import { apiRoutes } from '../../../../../apiRoutes';
import { useSearchParams } from 'react-router-dom';

const gridSx = { 
    pt: '0px!important'
}

const statuses =[ "Open", "Done","Closed"]

export default function SupportDetail({supportId, onClose}: any) {
    const [loading, setLoading] = useState<boolean>(false);
    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [images, setImages] = useState<any>([])
    const [currentImage, setCurrentImage] = useState<string | null>(null);
    let [searchParams, setSearchParams] = useSearchParams();
    const [alertInside, setAlertInside] = useState({
      text: "",
      severity: "success" as AlertColor,
    });
    const mutation = useUpdateSupport(supportId, (oldData: any, newData: any) => newData);
    const {data: data, isLoading} = useGetSupportById(+searchParams.get('supportId')!);
    const queryClient = useQueryClient();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        control,
        getValues
      } = useForm<ISupport>({
        resolver: yupResolver(supportUpdateSchema),
      })

    const onSubmit: SubmitHandler<ISupport> = async (data) => {
        try{
            setLoading(true);
            const res = await mutation.mutateAsync({
                note: data.note,
                status: data.status
            });
            queryClient.invalidateQueries({ queryKey: [apiRoutes.supports.base, undefined] })
            setAlertInside({text: 'Yadda saxlanıldı', severity: "success"});
            return setTimeout(()=> onClose(), 1000)
        }catch(err){
            console.log(err);
            setAlertInside({text: 'Xəta baş verdi', severity: "error"});
        }finally{
            setLoading(false);
            setAlertOpen(true);
        }
    }

    const handleCloseAlert = (
        reason?: string
      ) => {
        if (reason === "clickaway") {
          return;
        }
        setAlertOpen(false);
      };

    useEffect(()=> {
      if(data){
        setValue('title', data.title!);
        setValue('date', new Date(data.createdDate).toLocaleString());
        setValue('category', (data.supportCategory as any)?.name!);
        setValue('userEmail', data.user?.email);
        setValue('class', (data.class as any)?.name!);
        setValue('status', data.status!);
        setValue('description', data.description!);
        setValue('note', data.note!);
        setValue('phoneNumber', data.user?.phoneNumber)
        setImages(data.images);
      }
    }, [data]);

  return (
    <>
        <Box sx={{display: 'flex', justifyContent: 'start'}}>
            <IconButton aria-label="close" onClick={onClose}>
                <CloseIcon/>
            </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4} sx={{pt: '34px'}}>
                <Grid item xs={4} sx={gridSx}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Başlıq"
                      sx={{...sxField, width: '100%', mt:3, "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      }}}
                      {...register('title')}
                      disabled
                      InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={4} sx={gridSx}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Tarix"
                      sx={{...sxField, width: '100%', mt:3, "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      }}}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      {...register('date')}
                    />
                </Grid>
                <Grid item xs={4} sx={gridSx}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Səbəb"
                      sx={{...sxField, width: '100%', mt:3, "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      }}}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      {...register('category')}
                    />
                </Grid>
                <Grid item xs={4} sx={gridSx}>
                    <TextField
                      id="outlined-multiline-static"
                      label="İstifadəçi"
                      sx={{...sxField, width: '100%', mt:3, "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      }}}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      {...register('userEmail')}
                    />
                </Grid>
                <Grid item xs={4} sx={gridSx}>
                    <TextField
                      id="outlined-multiline-static"
                      label="İstifadəçinin telefon nömrəsi"
                      sx={{...sxField, width: '100%', mt:3, "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      }}}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      {...register('phoneNumber')}
                    />
                </Grid>
                <Grid item xs={4} sx={gridSx}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Qrup"
                      sx={{...sxField, width: '100%', mt:3, "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      }}}
                      disabled
                      InputLabelProps={{ shrink: true }}
                      {...register('class')}
                    />
                </Grid>
                <Grid item xs={4} sx={gridSx}>
                <Controller
                    render={({ field: { onChange, value } }) => (
                        <Autocomplete
                          sx={{...sxField, width: '100%', mt:3}}
                          options={statuses}
                          onChange={(e, val)=> onChange(val)}
                          value={value}
                          renderInput={(params) => <TextField {...params} label="Status" InputLabelProps={{ shrink: true }} />}
                        />
                    )}
                    name='status'
                    control={control}
                    defaultValue={getValues('status') ?? ''}
                />
                </Grid>
                <Grid item xs={12} sx={gridSx}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Açıqlama"
                      multiline
                      sx={{...sxField, width: '100%', mt:3, "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "#000000",
                      }}}
                      rows={4}
                      disabled
                      {...register('description')}
                      InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid item xs={12} sx={gridSx}>
                    <TextField
                      id="outlined-multiline-static"
                      label="Qeyd"
                      multiline
                      sx={{...sxField, width: '100%', mt:3}}
                      {...register('note')}
                      rows={4}
                      InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <Button sx={{mt: 3, mb: 5}} disabled={loading}>Yadda saxla</Button>
            <ImageList sx={{ maxWidth: 500, overflowY: 'hidden'}} cols={3}>
                {
                    images && images.map((img: any) => (
                        <ImageListItem className={styles.imgWrapper} key={img.id} sx={{ position: 'relative', mr: 2, height: '100px'}}>
                          <span className={styles.overlay}>
                              <OpenInFullIcon sx={{color: 'white'}} onClick={()=> setCurrentImage(img.path)}/>
                            </span>
                          <img
                            style={{borderRadius: '10px', height: '150px'}}
                            src={img.path}
                            loading="lazy"
                          />
                        </ImageListItem>
                    ))
                }
            </ImageList>
        </form>
        {
          currentImage !== null && (
            <ImageDetail imgSrc={currentImage!} handleClose={()=> setCurrentImage(null)}/>
          )
        }
        {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
        )}
    </>
  )
}
