import { createContext, useEffect } from "react";
import {
  loginAuth,
  logoutAuth,
  setLoading,
} from "../store/reducers/authReducer";
import { useDispatch } from "react-redux";
import { WorkerService } from "../network/services/workerService/WorkerService";
import axios from "axios";
import { storageHelper } from "../utils/auth/StorageHelper";
import { API_URL } from "../configs/Appconfig";

interface User {
  id: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
}
type LoginFunction = (data: any) => Promise<any>;
type ForgotPasswordFunction = (data: any) => Promise<any>;
interface AuthContextValue {
  login: LoginFunction;
  logout: () => void;
  isAuth: () => boolean;
  forgotPassword: ForgotPasswordFunction;
}
export const AuthContext = createContext<AuthContextValue | null>(null);
export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const workerService = new WorkerService();

  const init = async () => {
    try {
      dispatch(setLoading(true));
      const response: any = await axios.get(
        API_URL + "api/Users/login",
        // "https://code-academy-test.azurewebsites.net/api/Users/login",
        {
          withCredentials: true,
        }
      );
      if (response.status === 200) {
        dispatch(loginAuth(response.data));
      } else {
        dispatch(logoutAuth());
      }
    } catch (err) {
      dispatch(logoutAuth());
    } finally {
      dispatch(setLoading(false));
    }
  };
  useEffect(() => {
    init();
  }, []);
  const isAuth = () => {
    const userId = storageHelper.getStoreWithDecryption("userId");
    if (userId) {
      return true;
    }
    return false;
  };
  const login: LoginFunction = async (data) => {
    try {
      const response: any = await axios.post(API_URL + "api/Auth/login", data, {
        withCredentials: true,
      });
      if (response.status === 200) {
        init();
      } else {
        throw new Error(`Xəta baş verdi`);
      }
      return response;
    } catch (error) {
      dispatch(logoutAuth());
      throw error;
    }
  };
  const logout = () => {
    dispatch(logoutAuth());
  };
  const forgotPassword = async (data: any) => {
    try {
      const response = await axios.post(
        API_URL + "api/Auth/refresh-password",
        data
      );
      return response;
    } catch (error) {
      return error;
    }
  };
  return (
    <AuthContext.Provider value={{ login, isAuth, logout, forgotPassword }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
