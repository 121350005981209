import React, { useContext, useEffect, useState } from 'react';
import ModuleModal from '../module-modal';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import ModuleModalImage from '../../assets/moduleModal.svg';
import LessonHours from '../../assets/lessonHours.svg';
import { AttendanceStudentsContext } from '../../../../../../context/AttendanceStudentsContext';
import Alert from '../../../../../../components/custom-components/alert';
import { AlertColor } from "@mui/material/Alert";
import PresenceModal from '../PresenceModal';
import { AttendanceContext } from '../../../../../../context/AttendanceContext';
import { useEndAttendance, useJoinClass, useSaveAttendance, useStartAttendance } from '../../../../../../api/attendance';

function ModalOpen({classId, classTimeSheetId} : any) {
  const mutation = useStartAttendance((oldData: any, newData: any) => newData);
  const {isActive, setIsActive} = useContext(AttendanceContext);
  const endLessonMutation = useEndAttendance((oldData: any, newData: any) => newData);

  const handleLabStart = async () => {
    try{
      const res = await mutation.mutateAsync({
        classId: classId,
        sessionCategory: "Lab",
      })
      if(res.status === 200){
        setIsActive(true)
      }
    }catch(err){
      console.error(err)
    }
  }

  const handleLabEnd = async ()=> {
    try{
      const res = await endLessonMutation.mutateAsync({
        classTimeSheetId: classTimeSheetId
      })
      // if(res.status === 201){
        setIsActive(false)
      // }
    }catch(err){
      console.error(err)
    }
  }


 return(
  <>
  
  {
    isActive ? (
      <Button
      onClick={() => handleLabEnd()}
      variant="contained"
      className="saveBtn"
      sx={{
        background: "#854693",
        color: "#FFF",
        padding: "8px 24px",
        borderRadius: "12px",
        fontFamily: "Visby CF Bold, sans-serif",
        textTransform: "none",
        mt: '21px',
        mb: '12px',
        width: '100%',
        "&:hover": {
          background: "#6e3d7d",
        },
      }}
    >
      Labı bitir
    </Button>
    ) : (
    <Button
        onClick={() => handleLabStart()}
        variant="contained"
        className="saveBtn"
        sx={{
          background: "#854693",
          color: "#FFF",
          padding: "8px 24px",
          borderRadius: "12px",
          fontFamily: "Visby CF Bold, sans-serif",
          textTransform: "none",
          mt: '21px',
          mb: '12px',
          width: '100%',
          "&:hover": {
            background: "#6e3d7d",
          },
        }}
      >
        Labı başlat
      </Button>

    )
  }
  </>
  )
  }

export default function Index({classTimeSheetId, classId, worker} : any) {
    const {students} = useContext(AttendanceStudentsContext);
    const mutation = useSaveAttendance((oldData: any, newData: any) => newData);
    const {currentNum, isActive} = useContext(AttendanceContext)

    const [alertOpen, setAlertOpen] = useState<boolean>(false);
    const [alertInside, setAlertInside] = useState({
      text: "",
      severity: "success" as AlertColor,
    });

    const handleSave = async () => {
      try{
        const res = await mutation.mutateAsync({
          classTimeSheetsId: classTimeSheetId,
          attendances: students.map((st: any)=> {return {studentId: st.id, note: st.note, totalAttendanceHours: st.totalHours}})
        })
        if(res.status){
          setAlertInside({text: 'Yadda saxlanildi', severity: "success"});
          setAlertOpen(true);
        }
      }catch(err){
        setAlertInside({text: 'Xəta baş verdi', severity: "error"});
        setAlertOpen(true)
      }
    }
  return (
    <div>
      <Typography sx={{mb: '20px'}}>
        <span>Mentor: {worker?.name} {worker?.surname}</span>
      </Typography>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}>
        <div style={{display: 'flex'}}>
          <ModuleModal cardModel={{image: ModuleModalImage, title: 'Dərs qeydiyyatı', subtitle: '', color: '#C6DBEA'}} title="" width={490} children={<ModalOpen classId={classId} classTimeSheetId={classTimeSheetId}/>}/>
          <ModuleModal cardModel={{image: LessonHours, title: 'Aktivlik', subtitle: `İştirak saatı: ${currentNum}`, color: '#C7E3CB'}} title='' width={232} isSelect children={<PresenceModal/>}/>
        </div>
        <Button
            variant="contained"
            className="saveBtn"
            onClick={handleSave}
            disabled={!isActive}
            sx={{
              background: "#854693",
              color: "#FFF",
              padding: "8px 24px",
              borderRadius: "12px",
              fontFamily: "Visby CF Bold, sans-serif",
              textTransform: "none",
              mt: '21px',
              "&:hover": {
                background: "#6e3d7d",
              },
            }}
          >
           Yadda saxla
          </Button>
      </div>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={()=> setAlertOpen(false)}
        />
      )}
    </div>
  )
}
