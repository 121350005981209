import React, { useState } from "react";
import Header from "./components/header/Header";
import { Grid, TextField } from "@mui/material";
import "dayjs/locale/az";
import { FormControl } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import localizedFormat from "dayjs/plugin/localizedFormat";
import azLocale from "dayjs/locale/az";
import { useNavigate, useParams } from "react-router-dom";
import { sxDate } from "../../MrpManagement/mrp/filter/style";
import { sxField, sxFieldHeader } from "../../MrpManagement/mrp/components/modal/style";
import Button from "../../../../components/core-components/inputs/button";
import { BaseService } from "../../../../network/services/base/BaseService";
import Progress from "../../../../components/custom-components/progress";
import { HolidayModel } from "./models";
import { APP_PREFIX_PATH } from "../../../../configs/Appconfig";
import { sxFormControl } from "./styles";
function Edit() {
  const params = useParams();
  const { id } = params;
  const [startDate, setStartDate] = useState<string>("");
  const [selectedHoliday, setSelectedHoliday] = useState<HolidayModel>();
  const [endDate, setEndDate] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState("");
  const [selectedEndDate, setEndSelected] = useState<any | null>("");
  const [selectedStartDate, setStartSelected] = useState<any | null>("");
  const holidayService = new BaseService(`/api/holidays`);
  const navigate = useNavigate();
  const getDataById = async () => {
    if(id != 'new'){
      setLoading(true);
      const res = await holidayService.getById(id!);
      setSelectedHoliday(res.data);
      setEndSelected(dayjs(res.data.endDate));
      setStartSelected(dayjs(res.data.startDate));
      setDescription(res.data.description);
      setLoading(false);
    }
  };
  dayjs.extend(localizedFormat);

  let capitalizedMonthNames: any[] = [];

  if (azLocale.months && Array.isArray(azLocale.months)) {
    capitalizedMonthNames = azLocale.months.map((monthName) => {
      return monthName.charAt(0).toUpperCase() + monthName.slice(1);
    });
  }
  const handleUpdate = async () => {
    const formattedStartDate = dayjs(selectedStartDate!.$d!).format(
      "YYYY-MM-DD"
    );
    const formattedEndDate = dayjs(selectedEndDate!.$d!).format("YYYY-MM-DD");
    const data = {
      startDate: formattedStartDate,
      endDate: formattedEndDate,
      description: description,
    };

    try {
      const res = await holidayService.update(data, `/api/holidays/${id}`);

      if (res.statusCode === 200 || res!.statusCode === 201) {
        navigate(`${APP_PREFIX_PATH}/holidays`);
      } else {
        console.error(`Unexpected status code: ${res.statusCode}`);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const customAzLocale = {
    ...azLocale,
    months: capitalizedMonthNames,
  };
  function DateIcon() {
    return <CalendarTodayOutlinedIcon color="primary" />;
  }
  React.useEffect(() => {
    // setLoading(true);
    dayjs.locale(customAzLocale);
    getDataById();
    // setLoading(false);
  }, []);
  if (loading) {
    return <Progress />;
  }

  console.log('hereeee')

  return (
    <>
        <Header />
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <FormControl sx={sxFormControl}>
              <TextField
                defaultValue={selectedHoliday?.description}
                value={description}
                label="Başlıq"
                onChange={(e) => setDescription(e.target.value)}
                sx={sxField}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            {" "}
            <FormControl fullWidth sx={sxFormControl}>
              <LocalizationProvider
                locale={customAzLocale}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={"Tarixdən"}
                  format="DD MMMM YYYY"
                  defaultValue={selectedStartDate || ""}
                  value={selectedStartDate}
                  onChange={(date) => setStartSelected(date)}
                  sx={sxDate}
                  components={{
                    OpenPickerIcon: DateIcon,
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={sxFormControl}>
              <LocalizationProvider
                locale={customAzLocale}
                dateAdapter={AdapterDayjs}
              >
                <DatePicker
                  label={"Tarixinədək"}
                  format="DD MMMM YYYY"
                  defaultValue={selectedEndDate || ""}
                  value={selectedEndDate}
                  onChange={(date) => setEndSelected(date)}
                  sx={sxDate}
                  components={{
                    OpenPickerIcon: DateIcon,
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Grid>
          <Grid xs={6} item>
            {" "}
            <Button onClick={handleUpdate}>Yadda Saxla</Button>
          </Grid>
        </Grid>
    </>
  );
}
export default Edit;
