import React from "react";
import { Box } from "@mui/material";
import Breadcrumbs from "../../../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";
import Button from "../../../../../../components/core-components/inputs/button";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  return (
    <>
      <Box
        marginBottom={"24px"}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Box>
          <Breadcrumbs>
            <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />
            <BreadcrumbsLink name="Akademiya" url={APP_PREFIX_PATH} />
            <BreadcrumbsTypography name="İşçilər" />
          </Breadcrumbs>
          <PageTitle name="İşçilər" />
        </Box>

          <Button
            onClick={()=>navigate(`${APP_PREFIX_PATH}/workers/add`)}
          >
            İşçi əlavə et
          </Button>
      </Box>
    </>
  );
}

export default Header;
