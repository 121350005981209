import React, { useContext, useEffect, useState } from 'react';
import ModuleModal from '../module-modal';
import ModuleModalImage from '../../assets/moduleModal.svg';
import LessonHours from '../../assets/lessonHours.svg';
import {
  Box,
  Typography,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";
import { useGetProgramModulesList } from '../../../../../../api/programs';
import { useEndAttendance, useJoinClass, useSaveAttendance, useStartAttendance } from '../../../../../../api/attendance';
import { AttendanceStudentsContext } from '../../../../../../context/AttendanceStudentsContext';
import Alert from '../../../../../../components/custom-components/alert';
import { AlertColor } from "@mui/material/Alert";
import PresenceModal from '../PresenceModal';
import { AttendanceContext } from '../../../../../../context/AttendanceContext';
import { useSelector } from 'react-redux';

function ModuleSelect({id, classId, workerId, classTimeSheetId, setAlertInside, setAlertOpen} : any){
  const {isActive, setIsActive} = useContext(AttendanceContext);
  const [addInputs, setAddInputs] = useState([{index: 0}]);
  const [options, setOptions] = useState<any>([]);
  const {data: modules, isLoading} = useGetProgramModulesList(id);
  const mutation = useStartAttendance((oldData: any, newData: any) => newData);
  const endLessonMutation = useEndAttendance((oldData: any, newData: any) => newData);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  useEffect(()=> {
    const programs : any = [];
     (modules as any)?.modules?.forEach((elem: any)=> {
      programs.push(elem);
      if(elem.subModules){
        elem.subModules.forEach((subModl: any)=> {
          programs.push(subModl)
        })
      }
    })
    setOptions(programs)
  }, [modules]);

  const handleLessonStart = async () => {
    try{
      const res = await mutation.mutateAsync({
        classId: classId,
        sessionCategory: "Theoric",
        heldModulesIds: addInputs?.map((elem: any)=> elem.id)
      })
      if(res.status === 200){
        setIsActive(true)
      }
      else{
        setAlertInside({
        text: "Dər artıq başladılıb",
        severity: "error"});
        setAlertOpen(true)
      }
    }catch(err){
      console.error(err)
    }
  }

  const handleLessonFinish = async () => {
    try{
      const res = await endLessonMutation.mutateAsync({
        classTimeSheetId: classTimeSheetId
      })
      // if(res.status === 201){
        setIsActive(false)
      // }
    }catch(err){
      console.error(err)
    }
  }
  
  return(
    <div>
          {
            !isActive ? (
              <>
              <span style={{fontSize: '24px', fontWeight: '700', marginBottom: '18px', display: 'inline-block'}}>Modul seçin</span>
              {addInputs.sort((a, b)=> (a as any).index - (b as any).index).map((item, index) => (
                <Box key={index} display="flex" alignItems="center" gap="8px" sx={{mb: '18px'}}>
                        <Autocomplete
                          sx={{width: '100%'}}
                          value={item}
                          id={`grouped-demo-${index}`}
                          options={options ? options : [{label: 'test', name: 'test'}]}
                          getOptionLabel={(option) => (option as any).name ? `${(option as any).version} ${(option as any).name}` : '' }
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Modulu seçin və yazın" sx={{borderRadius: '12px'}} />
                          )}
                          renderOption={(props, option) => {
                              return <>
                                <li key={(option as any).id} {...props}>
                                  {(option as any).version} {(option as any).name}
                                </li>
                              </> 
                          }}
                          onChange={(event, item) => {
                            const inputs = addInputs.filter((elem)=> (elem as any).index !== index);
                            setAddInputs([...inputs, {...item, index: index}])
                          }}
                        />
                        <IconButton
                          onClick={() => setAddInputs(addInputs.filter((item)=> item.index !== index))}
                          sx={{
                            display: addInputs.length > 1 ? "block" : "none",
                            padding: "0",
                            "&:hover": {
                              backgroundColor: "transparent",
                            },
                          }}
                        >
                         <img src="/icons/remove-recyecle.svg" />
                        </IconButton>
                      </Box>
                    ))}
                  <Typography
                     onClick={() => setAddInputs([...addInputs, {index: addInputs.length}])}
                     style={{color: '#299AE8', textDecoration: 'underline', cursor: 'pointer'}}
                     component="span"
                  >
                    + Modul əlavə et
                  </Typography>
              </>
            ) : null
          }
          <div style={{display: 'flex', justifyContent: 'center'}}>
          <div style={{width: '100%'}}>
            {
              isActive ? (
                <Button
                  onClick={() => handleLessonFinish()}
                  variant="contained"
                  className="saveBtn"
                  sx={{
                    background: "#854693",
                    color: "#FFF",
                    padding: "8px 24px",
                    borderRadius: "12px",
                    fontFamily: "Visby CF Bold, sans-serif",
                    textTransform: "none",
                    mt: '21px',
                    mb: '12px',
                    width: '100%',
                    "&:hover": {
                      background: "#6e3d7d",
                    },
                  }}
                >
                  Dərsi bitir
                </Button>
              ) : (
                <Button
                  onClick={() => handleLessonStart()}
                  disabled={
                    addInputs.length === 1 && !(addInputs[0] as any).name
                  }
                  variant="contained"
                  className="saveBtn"
                  sx={{
                    background: "#854693",
                    color: "#FFF",
                    padding: "8px 24px",
                    borderRadius: "12px",
                    fontFamily: "Visby CF Bold, sans-serif",
                    textTransform: "none",
                    mt: '21px',
                    mb: '12px',
                    width: '100%',
                    "&:hover": {
                      background: "#6e3d7d",
                    },
                  }}
                >
                  Dərsi başlat
                </Button>
              )
            }
            {/* <Typography
             onClick={() => console.log()}
             style={{color: '#854693', textDecoration: 'underline', cursor: 'pointer', display: 'block', textAlign: 'center'}}
             component="span"
              >
                Lab başlat
              </Typography> */}
          </div>
        </div>
        <Dialog
          open={openDialog}
          onClose={()=> setOpenDialog(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Lab’da iştirak edəcəksinizmi? "}
          </DialogTitle>
          <DialogActions>
            <Button onClick={()=> {}}>Xeyr</Button>
            <Button onClick={()=> console.log()} autoFocus>
              Bəli
            </Button>
          </DialogActions>
        </Dialog>
    </div>
  )
}

function Mentor({classTimeSheetId}: any){
  const {isActive, setIsActive, setIsJoined, isJoined} = useContext(AttendanceContext);
  const mutation = useJoinClass((oldData: any, newData: any) => newData)
  const handleLessonAttend = async () => {
    try{
      const res = await mutation.mutateAsync({classTimeSheetId: classTimeSheetId});
      setIsActive(true);
      setIsJoined(true);
    }catch(err){
      console.error(err)
    }
  }
  return(
    <>
      {
        !isJoined ? (
          <Button
            onClick={() => handleLessonAttend()}
            variant="contained"
            className="saveBtn"
            disabled={!isActive}
            sx={{
              background: "#854693",
              color: "#FFF",
              padding: "8px 24px",
              borderRadius: "12px",
              fontFamily: "Visby CF Bold, sans-serif",
              textTransform: "none",
              mt: '21px',
              mb: '12px',
              width: '100%',
              "&:hover": {
                background: "#6e3d7d",
              },
            }}
          >
            Dərsə daxil ol
          </Button>
        ) : <></>
      }
    </>
  )
}

export default function Index({programId, worker, classId, classTimeSheetId}: any) {

  const {students} = useContext(AttendanceStudentsContext);
  const user = useSelector((state: any) => state.auth.user);
  const {currentNum, isActive} = useContext(AttendanceContext)
  const mutation = useSaveAttendance((oldData: any, newData: any) => newData);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });

  const handleSave = async () => {
    console.log(students)
    try{
      const res = await mutation.mutateAsync({
        classTimeSheetsId: classTimeSheetId,
        attendances: students.map((st: any)=> {return {studentId: st.id, note: st.note, totalAttendanceHours: st.totalHours}})
      })
      if(res.status){
        setAlertInside({text: 'Yadda saxlanildi', severity: "success"});
        setAlertOpen(true);
      }
    }catch(err){
      setAlertInside({text: 'Xəta baş verdi', severity: "error"});
      setAlertOpen(true)
    }
  }

  return (
    <div>
      <Typography sx={{mb: '20px'}}>
        <span>Müəllim: {worker?.name} {worker?.surname}</span>
      </Typography>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'end'}}>
        <div style={{display: 'flex'}}>
          <ModuleModal 
            cardModel={{image: ModuleModalImage, title: 'Dərs qeydiyyatı', subtitle: '', color: '#C6DBEA'}} 
            title="" 
            width={490} 
            children={
              user?.roleName?.find((x : any)=> x === 'muellim') ? (
                <ModuleSelect workerId={worker?.id} id={programId} classId={classId} classTimeSheetId={classTimeSheetId} setAlertOpen={setAlertOpen} setAlertInside={setAlertInside}/>
              ) : 
              user?.roleName?.find((x: any)=> x=== 'mentor') ? (
                <Mentor/>
              ) : null
            }/>
          <ModuleModal cardModel={{image: LessonHours, title: 'Aktivlik', subtitle: `İştirak saatı: ${currentNum}`, color: '#C7E3CB'}} title='' width={232} isSelect children={<PresenceModal/>}/>
        </div>
        <Button
            variant="contained"
            className="saveBtn"
            onClick={handleSave}
            disabled={!isActive}
            sx={{
              background: "#854693",
              color: "#FFF",
              padding: "8px 24px",
              borderRadius: "12px",
              fontFamily: "Visby CF Bold, sans-serif",
              textTransform: "none",
              mt: '21px',
              "&:hover": {
                background: "#6e3d7d",
              },
            }}
          >
           Yadda saxla
          </Button>
      </div>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={()=> setAlertOpen(false)}
        />
      )}
    </div>
  )
}


