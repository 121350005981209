import {
    useMutation,
    useQuery,
    useQueryClient,
  } from 'react-query';
import { QueryFunctionContext } from 'react-query/types/core/types';
import {ApiInstance} from './api';
import { AxiosError, AxiosResponse } from 'axios';

type QueryKeyT = [string, object | undefined];

export const fetcher = <T>({
  queryKey,
  pageParam,
}: QueryFunctionContext<QueryKeyT>): Promise<T> => {
  const [url, params] = queryKey;
  return ApiInstance
    .get<T>(url, { params: { ...params, pageParam } })
    .then((res) => res.data)
    .catch((err)=> {
      if(err.response?.status == 403){
        window.location.href = '/403';
      }
      return err;
    });
};

const useGenericMutation = <T, S>(
  func: (data: T | S) => Promise<AxiosResponse<S>>,
  url: string,
  params?: object,
  updater?: ((oldData: T, newData: S) => T) | undefined
) => {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, T | S>(func, {
    onMutate: async (data) => {
      await queryClient.cancelQueries([url!, params]);

      const previousData = queryClient.getQueryData([url!, params]);

      queryClient.setQueryData<T>([url!, params], (oldData) => {
        return updater ? updater(oldData!, data as S) : (oldData as T);
      });

      return previousData;
    },
    onError: (err, _, context) => {
      queryClient.setQueryData([url!, params], context);
    },
    onSettled: () => {
      queryClient.invalidateQueries([url!, params]);
    },
  });
};

export const useFetch = <T>(
    url: string | null,
    params?: object,
    config?: QueryKeyT
  ) => {
    const context = useQuery<T, Error, T, QueryKeyT>(
      [url!, params],
      ({ queryKey }) => fetcher({ queryKey, pageParam: null, meta: undefined }),
      {
        enabled: !!url,
        retry: 0,
        ...config,
      }
    );
  
    return context;
};

export const useDelete = <T>(
  url: string,
  params?: object,
  updater?: (oldData: T, id: string | number) => T
) => {
  return useGenericMutation<T, string | number>(
    (id) => ApiInstance.delete(`${url}/${id}`).catch((err)=> err.response.status === 403 ? window.location.href = '/403' : err),
    url,
    params,
    updater
  );
};

export const usePost = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S>(
    (data) => ApiInstance.post<S>(url, data).catch((err)=> err.response.status === 403 ? window.location.href = '/403' : err),
    url,
    params,
    updater
  );
};

export const useUpdate = <T, S>(
  url: string,
  params?: object,
  updater?: (oldData: T, newData: S) => T
) => {
  return useGenericMutation<T, S>(
    (data) => ApiInstance.put<S>(url, data).catch((err)=> err.response.status === 403 ? window.location.href = '/403' : err),
    url,
    params,
    updater
  );
};