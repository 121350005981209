import { AlertColor, FormControl, Grid, TextField } from "@mui/material";
import React, { useState } from "react";
import Errors from "../../../../auth-views/login/components/Errors";
import { sxField } from "../../../MrpManagement/mrp/components/modal/style";
import Alert from "../../../../../components/custom-components/alert";
import Button from "../../../../../components/core-components/inputs/button";
import { BaseService } from "../../../../../network/services/base/BaseService";
import Progress from "../../../../../components/custom-components/progress";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../../../configs/Appconfig";

function AddForm() {
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [surname, setSurname] = useState<string>("");
  const [fincode,setFincode] = useState<string>("")
  const [emailError, setEmailError] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [surnameError, setSurnameError] = useState<string>("");
  const [fincodeError,setFincodeError] = useState<string>("")
  const workerService = new BaseService("api/Workers");
  const [loading, setLoading] = useState<boolean>();
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });

  const navigate = useNavigate();

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };
  //regex for email
  const regex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"
  );

  //email should end with @code.edu.az
  const regex2 = new RegExp(
    // eslint-disable-next-line no-useless-escape
    "^[a-zA-Z0-9_.+-]+@code.edu.az$"
  );
  // fincode 
  const specialCharRegex = new RegExp(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/);

  const handleAdd = () => {
    if (!regex.test(email)) {
      setEmailError("Email düzgün deyil");
      return;
    }
    if (!regex2.test(email)) {
      setEmailError("Code Academy emaili qeyd edin");
      return;
    }
    if (!name) {
      setNameError("Ad əlavə edin");
      return;
    }
     
    if (!surname) {
      setSurnameError("Soyad əlavə edin");
      return;
    }
    if (!email) {
      setEmailError("Email əlavə edin");
      return;
    }
    if (!fincode) {
      setFincodeError("Fincode əlavə edin");
      return;
    }
    if (specialCharRegex.test(fincode)) {
      setFincodeError("Finkod xüsusi simvollardan ibarət olmamalıdır!");
      return;
    }
    if (fincode.length !== 7) {
      setFincodeError("Finkod tam olaraq 7 simvol uzunluğunda olmalıdır!");
      return;
    }
    const model = {
      name: name,
      surname: surname,
      email: email,
      fincode:fincode
    };
    setLoading(true);
    workerService.add(model)
      .then((res) => {
        if (res) {
          setAlertInside({
            text: "İşçi əlavə edildi",
            severity: "success",
          });

          setName("");
          setSurname("");
          setEmail("");
          setFincode("");

          setAlertOpen(true);
          navigate(`${APP_PREFIX_PATH}/worker-classes`)

        } else {
          setAlertInside({
            text: "Xəta baş verdi",
            severity: "error",
          });
          setName("");
          setSurname("");
          setEmail("");
          setFincode("");
          setAlertOpen(true);
        }
      })
      .catch((err) => {
        if (err.statusCode === 409) {
          setAlertInside({
            text: "Bu email ilə işçi əlavə olunub",
            severity: "error",
          });
          setAlertOpen(true)
        }
      })
      .finally(() => setLoading(false))
  }
  if (loading) {
    return <Progress />;
  }
  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <FormControl
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
              {
                right: "20px",
              },
              ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                right: "20px",
              },
              display: "flex",
            }}
          >
            <TextField
              onChange={(e) => {
                setName(e.target.value);
                setNameError("");
              }}
              value={name}
              label="Ad"
              sx={sxField}
            />
            {nameError && <Errors message={nameError} />}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
              {
                right: "20px",
              },
              ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                right: "20px",
              },
              display: "flex",
            }}
          >
            <TextField
              value={surname}
              onChange={(e) => {
                setSurname(e.target.value);
                setSurnameError("");
              }}
              label="Soyad"
              sx={sxField}
            />
            {surnameError && <Errors message={surnameError} />}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
              {
                right: "20px",
              },
              ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                right: "20px",
              },
              display: "flex",
            }}
          >
            <TextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError("");
              }}
              label="Email"
              sx={sxField}
            />
            {emailError && <Errors message={emailError} />}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            sx={{
              "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined":
              {
                right: "20px",
              },
              ".css-bpeome-MuiSvgIcon-root-MuiSelect-icon": {
                right: "20px",
              },
              display: "flex",
            }}
          >
            <TextField
              value={fincode}
              onChange={(e) => {
                setFincode(e.target.value);
                setFincodeError("");
              }}
              label="Fincode"
              sx={sxField}
              inputProps={{ maxLength: 7 }}
            />
            {fincodeError && <Errors message={fincodeError} />}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleAdd}
          >
            Əlavə et
          </Button>
        </Grid>
      </Grid>
      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}
    </>
  );
}

export default AddForm;
