import SvgIcon from "@mui/material/SvgIcon";
import { Student } from "../../../pages/app-views/shared/continuity/models/Student";
interface ChatLinesIconProps{
    student: any
}
export const ChatLinesIcon=({student}:ChatLinesIconProps)=>{
    return(
        <SvgIcon  sx={{ width: "18px", height: "18px" }}
        color={
            student.sessions?.find(
              (session: any) =>
                session.classSessionCategory ===
                "Theoric"
            )?.note
              ? "primary"
              : "secondary"
          }
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8 10L12 10L16 10"
            stroke="#468CBC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8 14L10 14L12 14"
            stroke="#468CBC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.8214 2.48697 15.5291 3.33782 17L2.5 21.5L7 20.6622C8.47087 21.513 10.1786 22 12 22Z"
            stroke="#468CBC"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </SvgIcon> 
    )
}