import { GridColDef } from "@mui/x-data-grid";
import Header from "./components/header";
import { Box, Typography } from "@mui/material";
import moment from "moment";
import DataGrid from "../../../../components/custom-components/data-grid";
import { useEffect, useState } from "react";
import { localeText } from "../../../../configs/Locales";
import { BaseService } from "../../../../network/services/base/BaseService";

export const StudentServeys = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [classesServey, setClassesServey] = useState<any[]>([]);
  const classesServeyService = new BaseService("/api/classes/survey");
  const getClassesServey = async () => {
    await classesServeyService
      .getAll()
      .then((res) => {
        setClassesServey(res.data);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getClassesServey();
  }, []);
  classesServey.sort((a, b) => {
    return new Date(b.surveyDate).getTime() - new Date(a.surveyDate).getTime();
  });

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Qrup",
      flex: 0.7,
    },
    {
      field: "program",
      headerName: "Proqram adı",
      flex: 1,
      renderCell(params) {
        return (
          <Typography sx={{ fontSize: "14px" }}>{params.value.name}</Typography>
        );
      },
    },
    {
      field: "startDate",
      headerName: "Başlanğıc tarixi",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "endDate",
      headerName: "Bitiş tarixi",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "surveyDate",
      headerName: "Anket tarixi",
      flex: 1,
      renderCell: (params) => {
        if (params.value === null) {
          return (
            <Typography
              sx={{
                width: "100%",
                fontFamily: "Visby CF Bold, sans-serif",
              }}
            >
              -
            </Typography>
          ); // Eğer mentors null
        }
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
  ];
  return (
    <>
      <Header />
      <Box sx={{ width: "100%" }}>
        <DataGrid
          data={classesServey}
          columnsData={columns}
          pageSizeData={pageSize}
          setPageSize={setPageSize}
          disableColumnMenu={false}
          localeText={localeText}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
            pagination: {
              labelRowsPerPage: "Səhifədəki sətir sayı:",
            },
          }}
        />
      </Box>
    </>
  );
};
