import LessonPage from '../LessonPage';
import LabPage from '../LabPage';
import { useGetSessionClassById } from '../../../../../../api/classes';
import StudentsTable from '../StudentsTable';
import { useEffect, useState, useContext } from 'react';
import { AttendanceStudentsContext } from '../../../../../../context/AttendanceStudentsContext';
import { AttendanceContext } from '../../../../../../context/AttendanceContext';
import Progress from '../../../../../../components/custom-components/progress';

export default function Index({tabValue, currentClass}: any) {

    const {data: session, isLoading} = useGetSessionClassById(currentClass.id, tabValue === 0 ? 'teacher' : 'mentor');
    const {students, setStudents} = useContext(AttendanceStudentsContext);
    const {addTotalHours, setStartTime, setEndTime, setIsJoined} = useContext(AttendanceContext);

    useEffect(()=> {
        if(session){
            setStudents(session.students?.map((student: any)=> {return {...student, note: student.session?.note, totalHours: student.session?.hour, isChecked: false}}));
            addTotalHours(session.totalHours);
            setStartTime(session.startTime);
            setEndTime(session.endTime);
            setIsJoined(session.isJoined);
        }
    }, [session]);


  return (
    <div>
        {
            isLoading ? (
                <Progress/>
            ) : (
                <>
                    {
                        tabValue === 0 ? (
                            <>
                            {
                                !session.class ? (
                                    <span>No lesson for today</span>
                                ) : (
                                    <>
                                        <div style={{marginBottom: '30px'}}>
                                            <LessonPage programId={currentClass.programId} worker={session?.worker} classId={currentClass.id} classTimeSheetId={session?.classTimeSheetId}/>
                                        </div>
                                        <StudentsTable type={'Theory'} students={students}/>
                                    </>
                                )
                            }
                            </>
                        ) : (
                            <>
                            {
                                !session.class ? (
                                    <span>No lab for today</span>
                                ) : (
                                    <>
                                    <div style={{marginBottom: '30px'}}>
                                        <LabPage classId={currentClass.id} worker={session?.worker} classTimeSheetId={session?.classTimeSheetId}/>
                                    </div>
                                    <StudentsTable type={'Lab.'} students={students}/>
                                    </>
                                 )
                            }
                                
                            </>
                        )
                    }
                </>
            )
        }
        
    </div>
  )
}
