import ConfirmModal from "../../../../../../components/custom-components/confirm-modal";
import { useNavigate } from "react-router-dom";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";
import { useDeletePermissionGroup } from "../../../../../../api/permissions";
interface RemoveGroupProps {
  refetch?: () => void;
  id: number;
  isFromDetail?: boolean;
  open: boolean;
  setShowActionDelete: (value: boolean) => void;
}
function RemoveGroupModal(props: RemoveGroupProps) {
  const navigate = useNavigate();
  const { open,setShowActionDelete,refetch, id, isFromDetail } = props;
 const mutation=useDeletePermissionGroup()
  const removeGroupHandler = (groupId: number) => {
     mutation.mutateAsync(groupId).then(()=>{
        isFromDetail && navigate(`${APP_PREFIX_PATH}/permissions`);
        refetch && refetch();
     }).catch((err)=>console.log(err))
  };
  return (
      <ConfirmModal
        text="Qrupu silmək istədiyinizdən əminsinizmi?"
        sendBtnText="Qrupu sil"
        isWarning={false}
        open={open}
        handleClose={(e: any) => {
          setShowActionDelete(false);
        }}
        handleAgree={(e: any) => {
          removeGroupHandler(id);
        }}
      />
  );
}

export default RemoveGroupModal;
