import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import Header from "./components/header";
import TabHeader from "./components/tab-haeder";
import TabPanel from "@mui/lab/TabPanel";
import GroupList from "./group-list";
import UserList from "./user-list";
import Progress from "../../../../components/custom-components/progress";
import Button from "../../../../components/core-components/inputs/button";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import { useGetPermissionGroupsList } from "../../../../api/permissions";
import { useGetUsersList } from "../../../../api/users";

function SetPermisions() {
  const [value, setValue] = useState("1");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const {
    data: permissionGroupsData,
    isLoading: isPermissionGroupsLoading,
    refetch: refetchPermissionGroups,
  } = useGetPermissionGroupsList();
  const {
    data: usersData,
    isLoading: isUsersLoading,
    refetch: refetchUsers,
  } = useGetUsersList();
  return (
    <>
      <Header>
        {value === "1" ? (
          <></>
        ) : (
          <Button>
            <PlusIcon style={{ marginRight: "8px" }} />
            İstifadəçi əlavə et
          </Button>
        )}
      </Header>
      <TabContext value={value}>
        <TabHeader handleChange={handleChange} value={value} />
        <TabPanel sx={{ padding: "30px 0px" }} value="1">
          {isPermissionGroupsLoading ? (
            <Progress />
          ) : (
            <GroupList
              users={usersData!}
              groups={permissionGroupsData!} 
              refetch={refetchPermissionGroups}
            />
          )}
        </TabPanel>
        <TabPanel sx={{ padding: "30px 0px" }} value="2">
          {isUsersLoading ? (
            <Progress />
          ) : (
            <UserList
              groups={permissionGroupsData}
              refetchUsers={refetchUsers}
              users={usersData!}
            />
          )}
        </TabPanel>
      </TabContext>
    </>
  );
}

export default SetPermisions;
