import Header from './components/Header';
import { Tab, Tabs } from "@mui/material";
import { useState } from 'react';
import { Box } from '@mui/system';
import TeacherMentorPageWrapper from './components/LessonLabPageWrapper';
import './style.css';
import { AttendanceStudentsContextProvider } from '../../../../context/AttendanceStudentsContext';
import { AttendanceContextProvider } from '../../../../context/AttendanceContext';

export const sxTab = {
    display: "flex",
    padding: "0px 8px",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "500",
    color: "#000000",
    textTransform: "none",
    "&.Mui-selected": {
      fontFamily: "Visby CF Bold, sans-serif",
      color: "#000000",
    },
};

export default function Index() {
    const [tabValue, setTablValue] = useState(0);
    const [currentClass, setCurrentClass] = useState(null);
    
  return (
    <div>
        {
            // classId !== false ? (
                <AttendanceContextProvider>
                    <Header setClass={setCurrentClass}/>
                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', borderBottom: '1px solid rgba(0, 0, 0, 0.10)', mb: '10px'}}>
                        <Tabs value={tabValue} sx={{ height: "40px" }}>
                            <Tab sx={sxTab} label="Dərs" onClick={()=>  setTablValue(0)} />
                            <Tab sx={sxTab} label="Lab" onClick={()=>  setTablValue(1)} />
                        </Tabs>
                    </Box>
                    {
                        currentClass !== null ? ( 
                           <AttendanceStudentsContextProvider>
                               <TeacherMentorPageWrapper tabValue={tabValue} currentClass={currentClass}/>
                           </AttendanceStudentsContextProvider> 
                        ) : null
                    }
                </AttendanceContextProvider>
            // ): <p>Hazırda aktiv qrupunuz yoxdu!</p>
        }
        
    </div>
  )
}
