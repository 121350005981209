import {
    useDelete,
      useFetch, usePost, useUpdate,
  } from '../utils/api/reactQuery';
  import { apiRoutes } from '../apiRoutes';

  export const useGetAttendanceWorkersList = (id: number) =>
  useFetch<any>(apiRoutes.attendances.workers(id), undefined, [apiRoutes.attendances.workers(id), undefined]);

  export const useStartAttendance = (
    updater: (oldData: any, newData: any) => any) => 
    usePost<any, any>(apiRoutes.attendances.start, undefined, updater);

  export const useEndAttendance = (
    updater: (oldData: any, newData: any) => any) => 
    usePost<any, any>(apiRoutes.attendances.end, undefined, updater);

  export const useSaveAttendance = (
      updater: (oldData: any, newData: any) => any) => 
      usePost<any, any>(apiRoutes.attendances.base, undefined, updater);

  export const useJoinClass = (
    updater: (oldData: any, newData: any) => any) => 
    usePost<any, any>(apiRoutes.attendances.join, undefined, updater);